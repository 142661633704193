<template>
  <sun-select
    v-model="selectedPublisher"
    :required="required"
    label="name"
    track-by="id"
    :options="publishers"
    :disabled="disabled"
    text-error="This field is required"
  ></sun-select>
</template>

<script>
export default {
  name: 'PublisherSelector',
  props: {
    value: {
      type: [Object, String], // when a publisher is deselected, the value is an empty string
      default: () => null,
    },
    required: {
      type: [String, Boolean],
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    publishers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedPublisher: null,
    };
  },
  watch: {
    selectedPublisher: {
      handler(publisher) {
        this.$emit('input', publisher);
      },
      deep: true,
    },
    value: {
      handler(publisher) {
        this.selectedPublisher = publisher;
      },
      deep: true,
    },
  },
  async created() {
    this.selectedPublisher = this.value;
  },
};
</script>

<style scoped></style>
