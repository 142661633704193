var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-12",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        { staticClass: "flex flex-1 text-gray-700" },
                        [
                          _vm._v(": "),
                          _vm.offer
                            ? _c("span", { staticClass: "text-orange-500" }, [
                                _vm._v(_vm._s(_vm.offer.name)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "section",
        { staticClass: "section" },
        [
          !_vm.isLoading
            ? _c(
                "sun-label-group",
                {
                  staticClass: "w-full text-right",
                  attrs: { text: "Select a publisher" },
                },
                [
                  _c("sun-select", {
                    staticClass: "w-full max-w-lg",
                    attrs: {
                      label: "name",
                      "track-by": "id",
                      options: _vm.offerPublishers,
                      disabled: _vm.isPublisherSelectDisabled,
                    },
                    model: {
                      value: _vm.selectedPublisher,
                      callback: function ($$v) {
                        _vm.selectedPublisher = $$v
                      },
                      expression: "selectedPublisher",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "w-48" },
                    [_c("line-loader", { staticClass: "mb-2" })],
                    1
                  ),
                  _c("line-loader", { staticClass: "h-8 max-w-lg" }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "section" },
        [
          _c("offer-detail-list", {
            staticClass: "text-sm flex pb-2",
            attrs: {
              offer: _vm.offer,
              "is-loading": _vm.isLoading,
              "publisher-details": _vm.publisherDetails,
              "direct-link": _vm.publisherDetails.directLink,
              "user-info": _vm.userInfo,
            },
          }),
          _c(
            "card-form",
            [
              _c("template", { slot: "title" }, [_vm._v("Creatives")]),
              _c(
                "template",
                { slot: "form" },
                [
                  _c("creative-list-by-type", {
                    staticClass: "justify-center",
                    attrs: {
                      offer: _vm.offer,
                      "default-publisher": _vm.selectedPublisher,
                      user: _vm.userInfo,
                      "user-is-publisher": "",
                    },
                    on: { update: _vm.updateOffer },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }