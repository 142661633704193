<template>
  <div class="creative-list border border-gray-300 rounded-md shadow-md">
    <slot></slot>
    <div v-if="!hasContent">
      <asterix-no-data subtitle="Create one using the button above" class="text-center">
        <template #title><slot name="no-data-title">There are not creatives yet</slot></template>
      </asterix-no-data>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreativeList',
  components: { AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData') },
  computed: {
    hasContent() {
      return !!this.$slots.default?.length;
    },
  },
};
</script>

<style scoped></style>
