<template>
  <div class="flex flex-col w-full">
    <div class="flex justify-end mb-2 px-2">
      <button-group v-model="displayOptsSeleced" :options="displayOpts" class="mr-4">
        <template #option="{ option }">
          <div class="flex align-baseline">
            <eye-svg v-if="option.id === 'preview'" class="w-4 h-4 mr-1" />
            <code-svg v-else class="w-5 h-5 mr-1" />

            <span>{{ option.name }}</span>
          </div>
        </template>
      </button-group>

      <button @click.prevent.stop="copyToClipboard(value)">
        <sun-popover :text-info="clipboardHint"><copy-svg class="h-4 w-4 m-1" /></sun-popover>
      </button>
    </div>

    <div v-if="selectedView.id === 'preview'" class="border border-dashed border-gray-400 text-center p-4">
      <slot><iframe class="w-full" :srcdoc="value" sandbox="allow-forms allow-popups allow-scripts" /></slot>
    </div>

    <code-editor v-else v-model="internalCode" :readonly="readonly"></code-editor>

    <sun-input :value="value" :required="required" text-error="Field is required"></sun-input>
  </div>
</template>

<script>
import ButtonGroup from '@/components/molecules/ButtonGroup';
import CodeEditor from '@/components/organisms/shared/CodeEditor';
import CodeSvg from '@/components/icons/CodeSvg';
import EyeSvg from '@/components/icons/EyeSvg';
import CopySvg from '@/components/icons/CopySvg';
import { copyToClipboard } from '@/utils/copyToClipboard';

export default {
  name: 'CodeEditorPreview',
  components: {
    ButtonGroup,
    CodeEditor,
    CodeSvg,
    CopySvg,
    EyeSvg,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: String,
      default: '',
    },
    isPublisherRole: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    clipboardHint: 'Copy to clipboard',
    internalCode: null,
    displayOptsSeleced: null,
    displayOpts: null,
  }),
  computed: {
    selectedView() {
      return this.displayOpts[this.displayOptsSeleced];
    },
  },
  watch: {
    value(newCode) {
      this.internalCode = newCode;
    },
    internalCode(newCode) {
      this.$emit('input', newCode);
    },
  },
  created() {
    this.displayOpts = this.isPublisherRole
      ? [{ id: 'preview', name: 'Preview' }]
      : [
          { id: 'preview', name: 'Preview' },
          { id: 'code', name: 'Code' },
        ];
    this.displayOptsSeleced = this.isPublisherRole ? 0 : 1;
    this.internalCode = this.value;
  },
  methods: {
    copyToClipboard(text) {
      if (!text) return;

      const origin = this.clipboardHint;
      copyToClipboard(text);
      this.clipboardHint = 'Copied!';

      setTimeout(() => {
        this.clipboardHint = origin;
      }, 500);
    },
  },
};
</script>

<style scoped>
::v-deep .code-editor {
  min-height: 140px;
}

::v-deep input {
  @apply hidden;
}
</style>
