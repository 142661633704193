<template>
  <div>
    <creative-list v-if="!isLoading">
      <creative-deeplink-item
        v-for="crea in creatives"
        :key="crea.id"
        :creative="crea"
        :show-actions="showActions"
        :is-selected-publisher="isCopyAvailable"
        @delete="$emit('delete', crea)"
        @edit="$emit('edit', crea)"
        @copy="onCopy"
      />
      <template #no-data-title> There are not <span class="italic">deeplink</span> creatives yet </template>
    </creative-list>
    <div v-else>
      <line-loader v-for="crea in creatives" :key="crea.id" class="h-14"></line-loader>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CreativeList from '@/components/molecules/modules/ecommerce/offer/CreativeList';
import CreativeDeeplinkItem from '@/components/molecules/modules/ecommerce/offer/CreativeDeeplinkItem';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import LineLoader from '@/components/atoms/Loaders/LineLoader';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'CreativeListDeeplink',
  components: {
    CreativeList,
    CreativeDeeplinkItem,
    LineLoader,
  },
  mixins: [creativeFormMixin],
  props: {
    offer: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    creatives: {
      type: Array,
      default: () => null,
    },
    isCopyAvailable: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    onCopy(creative) {
      copyToClipboard(creative.directLink);
      this.createToast(Toast.success('Success', 'The text has been copied!'));
    },
  },
};
</script>
<style scoped>
.h-14 {
  height: 3.6rem;
}
</style>
