var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "creative-list border border-gray-300 rounded-md shadow-md",
    },
    [
      _vm._t("default"),
      !_vm.hasContent
        ? _c(
            "div",
            [
              _c("asterix-no-data", {
                staticClass: "text-center",
                attrs: { subtitle: "Create one using the button above" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _vm._t("no-data-title", function () {
                            return [_vm._v("There are not creatives yet")]
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }