var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "overlay" },
    [
      _vm._t("default"),
      _c("div", { staticClass: "foreground" }, [
        _c("div", { staticClass: "foreground-bg" }),
        _c(
          "div",
          { staticClass: "foreground-actions" },
          [_vm._t("actions")],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }