import { CREATIVE_DEEPLINK_RESOURCE, OFFER_RESOURCE, PUBLISHER_TRACKERLINK_RESOURCE } from '@/services/keys';
import { api } from '@/services/modules/ecommerce/offer/creatives';

const resources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_DEEPLINK_RESOURCE]: undefined,
  [PUBLISHER_TRACKERLINK_RESOURCE]: undefined,
});

export async function getAllTrackerLinks(offerId, publisherId) {
  const partialUrl = api.createUrl(resources(offerId));
  const {
    data: { code },
  } = await api.create(partialUrl, { publisherId });
  return code;
}
