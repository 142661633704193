<template>
  <div>
    <creative-list v-if="!isLoading">
      <creative-feed-item
        v-for="crea in creatives"
        :key="crea.id"
        :creative="crea"
        :show-actions="showActions"
        :is-selected-publisher="isCopyAvailable"
        @delete="$emit('delete', crea)"
        @edit="$emit('edit', crea)"
        @copy="onCopy"
      />
      <template #no-data-title> There are not <span class="italic">feeds</span> creatives yet </template>
    </creative-list>
    <div v-else>
      <line-loader v-for="crea in creatives" :key="crea.id" class="h-14"></line-loader>
    </div>
  </div>
</template>

<script>
import CreativeList from '@/components/molecules/modules/ecommerce/offer/CreativeList';
import CreativeFeedItem from '@/components/molecules/modules/ecommerce/offer/CreativeFeedItem';
import { getPublisherTrackerLink } from '@/services/modules/ecommerce/offer/creatives/feed/getPublisherTrackerLink';
import { copyToClipboard } from '@/utils/copyToClipboard';
import CreativeFeed from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeed';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import apiRequest from '@/utils/apiRequest';
import LineLoader from '@/components/atoms/Loaders/LineLoader';
import { CONTEXTS } from '@/model/shared/contexts';

export default {
  name: 'CreativeListFeed',
  components: {
    CreativeList,
    CreativeFeedItem,
    LineLoader,
  },
  mixins: [creativeFormMixin],
  props: {
    offer: {
      type: Object,
      default: () => ({}),
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    creatives: {
      type: Array,
      default: () => null,
    },
    isCopyAvailable: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    async onCopy(creative) {
      if (!creative.publisher || !this.offer.id) return;
      const feedCreative = new CreativeFeed(creative.id, creative.name, creative.publisher, creative.feedId);
      this.setLoadingToast(feedCreative);
      await apiRequest(async () => {
        const { data } = await getPublisherTrackerLink(this.offer.id, feedCreative);
        const urlToCopy = `${process.env.VUE_APP_API_PROTOCOL}://${CONTEXTS.ECOMMERCE.subdomainUrl}.${process.env.VUE_APP_API_BASE_URL}${data}`;
        copyToClipboard(urlToCopy);
        this.setSuccessLoadingToast();
      }).catch(() => {
        this.setErrorLoadingToast();
      });
    },
  },
};
</script>
<style scoped>
.h-14 {
  height: 3.6rem;
}
</style>
