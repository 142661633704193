<template>
  <component
    :is="creativeComponent"
    :offer="offer"
    :default-publisher="defaultPublisher"
    :deep-link-tracker="deepLinkTracker"
    @change="$emit('change', $event)"
    @updatePublisher="defaultPublisher = $event"
    @deepLinkTracker="deepLinkTracker = $event"
  ></component>
</template>

<script>
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import CreativeBanner from '@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeBanner';
import CreativeHtml from '@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeHtml';
import CreativeDeeplink from '@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeDeeplink';
import CreativeTextlink from '@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeTextlink';
import CreativeFeed from '@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeFeed';

export default {
  name: 'CreativeFactory',
  components: {
    CreativeBanner,
    CreativeHtml,
    CreativeDeeplink,
    CreativeTextlink,
    CreativeFeed,
  },
  props: {
    /** @type CREATIVE_TYPES */
    type: {
      type: Object,
      required: true,
      validator: type => {
        return Object.keys(CREATIVE_TYPES).includes(type.id);
      },
    },
    /** @type Offer */
    offer: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      defaultPublisher: null,
      deepLinkTracker: null,
    };
  },
  computed: {
    creativeComponent() {
      let component = null;

      switch (this.type?.id) {
        case CREATIVE_TYPES.BANNER.id:
          component = 'CreativeBanner';
          break;
        case CREATIVE_TYPES.HTML.id:
          component = 'CreativeHtml';
          break;
        case CREATIVE_TYPES.DEEPLINK.id:
          component = 'CreativeDeeplink';
          break;
        case CREATIVE_TYPES.TEXTLINK.id:
          component = 'CreativeTextlink';
          break;
        case CREATIVE_TYPES.FEED.id:
          component = 'CreativeFeed';
          break;
      }
      return component;
    },
  },
};
</script>

<style scoped></style>
