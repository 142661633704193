var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("line-loader")
        : _c(
            "div",
            { class: _vm.classes },
            [
              _vm.title
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-md font-bold mr-1 whitespace-no-wrap text-gray-600",
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm._t("content", function () {
                return [
                  _c(
                    "span",
                    { staticClass: "whitespace-no-wrap truncate" },
                    [_vm._t("text")],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }