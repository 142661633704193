<template>
  <creative-item
    :creative="creative"
    :show-actions="showActions"
    :is-selected-publisher="isSelectedPublisher"
    @delete="$emit('delete', $event)"
    @edit="$emit('edit', $event)"
    @copy="$emit('copy', $event)"
  >
    <form-row>
      <sun-label-group class="w-full" text="Title">
        <asterix-input v-model="creative.name" type="text" disabled />
      </sun-label-group>
    </form-row>
    <form-row>
      <sun-label-group class="w-full mt-2" text="Target Url">
        <asterix-input v-model="creative.targetUrl" type="text" disabled />
      </sun-label-group>
    </form-row>
  </creative-item>
</template>

<script>
import AsterixInput from '@/components/atoms/AsterixInput';
import CreativeItem from '@/components/molecules/modules/ecommerce/offer/CreativeItem';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'CreativeLinkItem',
  components: {
    FormRow,
    CreativeItem,
    AsterixInput,
  },
  props: {
    /** @type [CreativeDeeplink, CreativeTextLink] */
    creative: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isSelectedPublisher: {
      type: Boolean,
      defalut: () => false,
    },
  },
};
</script>
