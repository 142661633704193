<!-- TODO revisar !! -->
<template>
  <div class="w-full text-sm">
    <card-form>
      <template #title>History</template>
      <template slot="form">
        <sun-data-table
          :headers="entity.headers"
          :content="entity.data"
          striped
          hoverable
          :loading="isLoading"
          class="w-full m-2 max-height-25 overflow-auto"
        >
          <template #empty>
            <asterix-no-data class="bg-white text-center" />
          </template>
        </sun-data-table>
      </template>
    </card-form>
  </div>
</template>

<script>
import CardForm from '@/components/atoms/CardForm';

export default {
  name: 'OfferHistory',
  components: { CardForm, AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData') },
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style scoped>
.max-height-25 {
  max-height: 25rem;
}
</style>
