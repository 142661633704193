var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showFormModal
        ? _c("creative-banner-modal", {
            attrs: {
              creative: _vm.creativeToUpdate,
              "is-loading": _vm.modalLoading,
            },
            on: { submit: _vm.onFormSubmit, cancel: _vm.closeModal },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "sun-button",
            {
              staticClass: "custom-p-1 text-xs",
              attrs: { variant: "pill" },
              on: {
                click: function ($event) {
                  _vm.showFormModal = true
                },
              },
            },
            [_vm._v("+ new creative")]
          ),
        ],
        1
      ),
      _vm.allCreatives.length
        ? _c(
            "form-row",
            [
              _c(
                "sun-label-group",
                {
                  staticClass: "w-full",
                  attrs: { text: "Select publisher to get its code" },
                },
                [
                  _c("publisher-selector", {
                    attrs: { publishers: _vm.publishers, required: "required" },
                    model: {
                      value: _vm.selectedPublisher,
                      callback: function ($$v) {
                        _vm.selectedPublisher = $$v
                      },
                      expression: "selectedPublisher",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form-row",
        [
          _c("creative-list-banner", {
            staticClass: "w-full",
            attrs: {
              "show-actions": "",
              offer: _vm.offer,
              "is-loading": _vm.isLoadingUpdateOffer,
              creatives: _vm.allCreatives,
              "is-copy-available": _vm.isSelectedPublisher,
            },
            on: { delete: _vm.onDelete, edit: _vm.onEdit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }