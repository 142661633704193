<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700"
          >: <span v-if="offer" class="text-orange-500">{{ offer.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <section class="section">
      <sun-label-group v-if="!isLoading" text="Select a publisher" class="w-full text-right">
        <sun-select
          v-model="selectedPublisher"
          class="w-full max-w-lg"
          label="name"
          track-by="id"
          :options="offerPublishers"
          :disabled="isPublisherSelectDisabled"
        ></sun-select>
      </sun-label-group>
      <div v-else>
        <div class="w-48">
          <line-loader class="mb-2" />
        </div>
        <line-loader class="h-8 max-w-lg" />
      </div>
    </section>
    <section class="section">
      <offer-detail-list
        :offer="offer"
        :is-loading="isLoading"
        :publisher-details="publisherDetails"
        :direct-link="publisherDetails.directLink"
        :user-info="userInfo"
        class="text-sm flex pb-2"
      >
      </offer-detail-list>
      <card-form>
        <template slot="title">Creatives</template>
        <template slot="form">
          <creative-list-by-type
            class="justify-center"
            :offer="offer"
            :default-publisher="selectedPublisher"
            :user="userInfo"
            user-is-publisher
            @update="updateOffer"
          />
        </template>
      </card-form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { stringToDate } from '@/filters/dateFilters';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { getOfferById } from '@/services/modules/ecommerce/offer';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { USER } from '@/store/modules/auth/keys';
import { ROLES } from '@/model/shared/roles';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import Offer from '@/entities/ecommerce/Offer';
import OfferDetailList from '@/components/organisms/modules/ecommerce/offer/OfferDetailList.vue';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getUserById } from '@/services/modules/Core/user';
import LineLoader from '@/components/atoms/Loaders/LineLoader';
import CardForm from '@/components/atoms/CardForm';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export default {
  name: 'OfferDetails',
  components: {
    Breadcrumb,
    MetaActionsHeader,
    OfferDetailList,
    LineLoader,
    CreativeListByType: () => import('@/components/organisms/modules/ecommerce/offer/creatives/CreativeListByType.vue'),
    CardForm,
  },
  filters: {
    stringToDate,
  },
  data: () => ({
    isLoading: true,
    offer: new Offer(),
    userInfo: null,
    offerPublishers: [],
    selectedPublisher: null,
    roles: ROLES,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
    }),
    isPublisherSelectDisabled() {
      return this.offerPublishers.length < 2;
    },
    publisherDetails() {
      return {
        price: this.selectedPublisher?.publisherPrice,
        directLink: this.selectedPublisher?.directLink,
        status: this.selectedPublisher?.status,
      };
    },
  },
  watch: {
    offer(offer) {
      this.userInfo?.publishers.forEach(usPublisher => {
        const publisher = offer.publishers.find(pub => pub.publisherId === usPublisher.id);
        if (publisher) {
          publisher.name = usPublisher.name;
          this.offerPublishers.push(publisher);
        }
      });
      this.offerPublishers.sort((a, b) => {
        if (a.name === b.name) return 0;
        return a.name > b.name ? 1 : -1;
      });

      this.selectedPublisher = this.offerPublishers[0];
    },
  },
  async created() {
    this.isLoading = true;
    await Promise.all([this.getUserInfo(), this.getOfferData()]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async getUserInfo() {
      const user = await getUserById(this.user.id, new QueryParamsBuilder(), 'offerDetailUser');
      this.userInfo = user.data;
    },
    async getOfferData() {
      try {
        const { data } = await getOfferById(this.$route.params.offerId);
        this.offer = data;
        this.offer.start = stringToDate(this.offer.start);
        this.offer.end = stringToDate(this.offer.end);
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get Offer`, error.message));
        }
      }
    },
    async updateOffer() {
      this.isLoading = true;
      await this.getOfferData();
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.section {
  @apply p-6 bg-white rounded-lg shadow-md mb-8;
}

.section .title {
  @apply font-bold text-xl text-left mb-3;
}

.subtitle {
  @apply text-lg font-semibold text-left mb-4 mt-5;
}

::v-deep .creative-list-type {
  @apply shadow-none px-10 py-4;
}
</style>
