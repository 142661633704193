var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.offer
    ? _c(
        "section",
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
            },
            [
              _c(
                "header",
                { staticClass: "flex justify-between items-center" },
                [
                  _c("h1", { staticClass: "text-xl text-gray-700 font-bold" }, [
                    _vm._v("Targeting"),
                  ]),
                  _c(
                    "asterix-dropdown-multiple",
                    {
                      on: { change: _vm.onNewTarget },
                      model: {
                        value: _vm.targets,
                        callback: function ($$v) {
                          _vm.targets = $$v
                        },
                        expression: "targets",
                      },
                    },
                    [_vm._v(" + add target ")]
                  ),
                ],
                1
              ),
              _vm.targetsToShow.length
                ? _c(
                    "ul",
                    _vm._l(_vm.targetsToShow, function (target) {
                      return _c(
                        "li",
                        { key: target.id },
                        [
                          _c("include-item-list", {
                            staticClass: "mt-4",
                            attrs: {
                              "allow-exclude": "",
                              collapsible: "",
                              include: target,
                            },
                            on: { edit: _vm.onNewTarget },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("asterix-no-data", {
                        staticClass: "text-center",
                        attrs: {
                          title: "No targets yet",
                          subtitle:
                            "Use the add button above to create your first targeting",
                        },
                      }),
                    ],
                    1
                  ),
            ]
          ),
          _vm.isLoading || !_vm.offer
            ? _c(
                "div",
                [
                  _c("card-form-loading"),
                  _c("card-form-loading", { staticClass: "mt-10" }),
                  _c("card-form-loading", { staticClass: "mt-10" }),
                  _c("card-form-loading", { staticClass: "mt-10" }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "sun-form",
            {
              staticClass: "flex mt-12",
              on: {
                submit: _vm.submitForm,
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("save-button", {
                attrs: {
                  id: "offer-submit",
                  loading: _vm.isLoading,
                  text: _vm.offer.id ? "Save" : "Create",
                },
              }),
            ],
            1
          ),
          _vm.targetToCreate
            ? _c("include-modal", {
                attrs: {
                  "allow-exclude": "",
                  "base-target": _vm.targetToCreate,
                },
                on: {
                  cancel: _vm.onNewTargetCancel,
                  update: _vm.onNewTargetAdded,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }