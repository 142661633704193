<template>
  <div class="creative w-full">
    <collapsible-container>
      <template #title>
        <div class="flex w-full pr-2 items-center">
          <h1 class="">{{ creative.name }}</h1>
          <status-pill v-if="showStatusPill" color="red" :status="creative.status" class="ml-4" />
          <div class="flex flex-1 justify-end">
            <button
              v-if="showActions"
              class="hover:bg-gray-400 action-button mr-1"
              @click.stop.prevent="$emit('edit', creative)"
            >
              <edit-svg class="w-4 h-4" />
            </button>

            <sun-popover class="mr-1 static flex-none" :text-info="clipboardHint">
              <button
                class="hover:bg-gray-400 action-button align-bottom"
                :class="{ disabled: isDisabled }"
                @click.stop.prevent="emitCopy"
              >
                <copy-svg class="w-4 h-4" />
              </button>
            </sun-popover>

            <button
              v-if="showActions"
              class="hover:bg-red-400 text-white action-button"
              @click.stop.prevent="showDelete"
            >
              <rubbis-bin-svg class="w-4 h-4" />
            </button>
          </div>
        </div>
      </template>

      <div class="p-4 border-t border-gray-300">
        <slot></slot>
      </div>
    </collapsible-container>
    <delete-modal :open="showDeleteModal" @confirm="confirmDeleteModal" @cancel="showDeleteModal = false">
      <template #description> {{ deleteModalDescription }}</template>
    </delete-modal>
  </div>
</template>

<script>
import RubbisBinSvg from '@/components/icons/RubbishBinSvg';
import CollapsibleContainer from '@/components/atoms/CollapsibleContainer';
import CopySvg from '@/components/icons/CopySvg';
import EditSvg from '@/components/icons/EditSvg';
import CREATIVE_FEED_STATUS from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeedStatus';
import StatusPill from '@/components/atoms/StatusPill';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'CreativeItem',
  components: {
    StatusPill,
    EditSvg,
    DeleteModal,
    CollapsibleContainer,
    CopySvg,
    RubbisBinSvg,
  },
  props: {
    /** @type CreativeDeeplink */
    creative: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isSelectedPublisher: {
      type: Boolean,
      defalut: () => false,
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.creative.publisher || this.creative?.status === CREATIVE_FEED_STATUS.BLOCKED;
    },
    clipboardHint() {
      return this.creative.publisher ? 'Copy to clipboard' : 'You need to pick a publisher first';
    },
    deleteModalDescription() {
      return `You are going to remove ${this.creative.name}. This action can not be undone.`;
    },
    showStatusPill() {
      return this.creative?.status === CREATIVE_FEED_STATUS.BLOCKED;
    },
  },
  methods: {
    confirmDeleteModal() {
      this.$emit('delete', this.creative);
      this.showDeleteModal = false;
    },
    showDelete() {
      this.showDeleteModal = true;
    },
    emitCopy() {
      if (!this.isDisabled) {
        this.$emit('copy', this.creative);
      }
    },
  },
};
</script>

<style scoped>
.action-button {
  @apply p-1 rounded-md text-gray-700;
}

.creative {
  @apply border-b border-gray-300;
}
::v-deep .collapsible-container-header:hover {
  @apply bg-gray-300;
}

::v-deep .collapsible-container-header {
  @apply py-4 pl-4 pr-3 items-center bg-gray-200;
}

.action-button.text-white:hover svg {
  @apply text-white;
}

.action-button.disabled {
  @apply text-gray-500 cursor-not-allowed;
}
</style>
