var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showFormModal
        ? _c("creative-feed-modal", {
            attrs: { loading: _vm.isLoading },
            on: { submit: _vm.onFormSubmit, cancel: _vm.closeModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.creativeFormTitle) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3287360029
            ),
            model: {
              value: _vm.creative,
              callback: function ($$v) {
                _vm.creative = $$v
              },
              expression: "creative",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "sun-button",
            {
              staticClass: "custom-p-1 text-xs",
              attrs: { variant: "pill" },
              on: {
                click: function ($event) {
                  _vm.showFormModal = true
                },
              },
            },
            [_vm._v("+ new creative")]
          ),
        ],
        1
      ),
      _vm.feedAmount
        ? _c(
            "form-row",
            [
              _c(
                "sun-label-group",
                {
                  staticClass: "w-full mt-8",
                  attrs: { text: "Select publisher to get its code" },
                },
                [
                  _c("publisher-selector", {
                    attrs: { publishers: _vm.publishers },
                    model: {
                      value: _vm.selectedPublisher,
                      callback: function ($$v) {
                        _vm.selectedPublisher = $$v
                      },
                      expression: "selectedPublisher",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("creative-list-feed", {
        key: _vm.feedAmount,
        staticClass: "mt-8",
        attrs: {
          "show-actions": "",
          offer: _vm.offer,
          "is-loading": _vm.isLoadingUpdateOffer,
          creatives: _vm.allCreatives,
          "is-selected-publisher": _vm.isSelectedPublisher,
        },
        on: { delete: _vm.onDeleteCreative, edit: _vm.onEditCreative },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }