<template>
  <section v-if="offer">
    <div v-show="!isLoading">
      <header class="flex justify-between items-center">
        <h1 class="text-xl text-gray-700 font-bold">Targeting</h1>
        <asterix-dropdown-multiple v-model="targets" @change="onNewTarget"> + add target </asterix-dropdown-multiple>
      </header>

      <ul v-if="targetsToShow.length">
        <li v-for="target in targetsToShow" :key="target.id">
          <include-item-list
            class="mt-4"
            allow-exclude
            collapsible
            :include="target"
            @edit="onNewTarget"
          ></include-item-list>
        </li>
      </ul>

      <div v-else>
        <asterix-no-data
          title="No targets yet"
          subtitle="Use the add button above to create your first targeting"
          class="text-center"
        />
      </div>
    </div>

    <div v-if="isLoading || !offer">
      <card-form-loading></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
    </div>

    <sun-form class="flex mt-12" @submit="submitForm" @keypress.enter="submitForm">
      <save-button id="offer-submit" :loading="isLoading" :text="offer.id ? 'Save' : 'Create'" />
    </sun-form>

    <include-modal
      v-if="targetToCreate"
      allow-exclude
      :base-target="targetToCreate"
      @cancel="onNewTargetCancel"
      @update="onNewTargetAdded"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { OFFER_RESOURCE } from '@/services/keys';
import SaveButton from '@/components/atoms/SaveButton';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { deepClone } from '@/utils/deepClone';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import AsterixDropdownMultiple from '@/components/molecules/shared/AsterixDropdownMultiple';
import INCLUDE_NAMES from '../../../../../../../model/modules/ecommerce/offer/IncludeNames.js';
import IncludeItemList from '@/components/organisms/shared/includes/IncludeItemList';
import IncludeModal from '@/components/organisms/shared/includes/IncludeInput';
import IncludeItem from '@/model/shared/IncludeItem';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';

import { updateTarget } from '@/services/modules/ecommerce/offer';

export default {
  name: 'Targeting',
  components: {
    IncludeModal,
    AsterixNoData,
    AsterixDropdownMultiple,
    SaveButton,
    CardFormLoading,
    IncludeItemList,
  },
  props: {
    /** @type Offer */
    baseOffer: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: false,
    hasError: false,
    currentApi: new EcommerceApi(),
    resource: OFFER_RESOURCE,
    offer: null,
    targets: [],
    targetToCreate: null,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    targetsToShow() {
      return this.targets.filter(t => t.value.type !== IncludeItem.VALID_TYPES.NONE && t.value.value.length);
    },
  },
  watch: {
    baseOffer(newVal) {
      this.loadOffer(newVal);
    },
  },
  async created() {
    this.isLoading = true;
    this.loadOffer(this.baseOffer);
    this.isLoading = false;
  },
  beforeDestroy() {
    this.targets.forEach(t => (this.offer.target[t.id] = t.value));
    this.$emit('update', this.offer);
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    loadOffer(newOffer) {
      this.offer = deepClone(newOffer);
      this.updateTargets();
    },
    updateTargets() {
      if (this.offer?.target) {
        // Target are loaded in a more rich entity (TargetItem) to help on the presentation login
        this.targets =
          Object.entries(this.offer.target)?.map(entry => {
            const id = entry[0];
            const value = entry[1];
            const name = INCLUDE_NAMES[id];
            return new IncludeItem(id, name, new IncludeItem(value.type, value.value));
          }) || [];
      } else {
        this.targets = [];
      }
    },
    onNewTarget(target) {
      this.targetToCreate = target;
    },
    onNewTargetCancel() {
      const update = this.targets.find(t => t.id === this.targetToCreate.id);
      this.$set(update, 'selected', this.targetToCreate.value.value?.length);

      this.targetToCreate = null;
    },
    onNewTargetAdded(target) {
      this.targetToCreate = null;
      const update = this.targets.find(t => t.id === target.id);

      this.$set(update, 'value', target.value);
      this.$set(update, 'selected', target.value.value?.length);
    },
    async submitForm() {
      await updateTarget(this.offer.id, this.offer.target);
    },
  },
};
</script>
