var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sun-select", {
    attrs: {
      required: _vm.required,
      label: "name",
      "track-by": "id",
      options: _vm.publishers,
      disabled: _vm.disabled,
      "text-error": "This field is required",
    },
    model: {
      value: _vm.selectedPublisher,
      callback: function ($$v) {
        _vm.selectedPublisher = $$v
      },
      expression: "selectedPublisher",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }