var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "creative-list",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "no-data-title",
                    fn: function () {
                      return [
                        _vm._v(" There are not "),
                        _c("span", { staticClass: "italic" }, [
                          _vm._v("deeplink"),
                        ]),
                        _vm._v(" creatives yet "),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1917041842
              ),
            },
            _vm._l(_vm.creatives, function (crea) {
              return _c("creative-deeplink-item", {
                key: crea.id,
                attrs: {
                  creative: crea,
                  "show-actions": _vm.showActions,
                  "is-selected-publisher": _vm.isCopyAvailable,
                },
                on: {
                  delete: function ($event) {
                    return _vm.$emit("delete", crea)
                  },
                  edit: function ($event) {
                    return _vm.$emit("edit", crea)
                  },
                  copy: _vm.onCopy,
                },
              })
            }),
            1
          )
        : _c(
            "div",
            _vm._l(_vm.creatives, function (crea) {
              return _c("line-loader", { key: crea.id, staticClass: "h-14" })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }