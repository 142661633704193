<template>
  <div>
    <asterix-form-modal
      v-if="showFormModal"
      :title="creativeFormTitle"
      closable
      @submit="onFormSubmit"
      @cancel="closeModal"
    >
      <template #content>
        <form-row>
          <sun-label-group class="w-full" text="Select size">
            <sun-select
              v-model="creative.size"
              required="required"
              label="name"
              text-error="the field is required."
              track-by="id"
              :options="availableSizes"
            />
          </sun-label-group>
        </form-row>

        <form-row>
          <sun-label-group class="w-full" text="Title">
            <asterix-input
              id="name"
              v-model="creative.name"
              type="text"
              name="name"
              validate-on-blur
              :minlength="3"
              :maxlength="50"
              text-error="the field is required."
              placeholder="My amazing creative"
              required="required"
            />
          </sun-label-group>
        </form-row>

        <form-row>
          <sun-label-group class="w-full" text="HTML Code">
            <code-editor v-model="creative.code"></code-editor>
          </sun-label-group>
        </form-row>

        <form-row>
          <sun-label-group class="w-full" text="Keep HTML links">
            <sun-toggle v-model="creative.keepHtmlLinks" />
          </sun-label-group>
        </form-row>

        <form-row>
          <sun-label-group class="w-full" text="External Code">
            <code-editor v-model="creative.externalCode"></code-editor>
          </sun-label-group>
        </form-row>
      </template>
      <template #footer>
        <creative-modal-footer :loading="modalLoading" @cancel="closeModal" />
      </template>
    </asterix-form-modal>

    <div class="text-right">
      <sun-button variant="pill" class="custom-p-1 text-xs" @click="showFormModal = true">+ new creative</sun-button>
    </div>

    <sun-form v-if="allCreatives.length" class="pt-8">
      <form-row>
        <sun-label-group class="w-full" text="Select publisher to get its code">
          <publisher-selector v-model="selectedPublisher" :publishers="publishers"></publisher-selector>
        </sun-label-group>
      </form-row>
    </sun-form>

    <creative-list-html
      :key="allCreatives.length"
      class="mt-8"
      show-actions
      :offer="offer"
      :is-loading="isLoadingUpdateOffer"
      :creatives="allCreatives"
      :is-copy-available="isSelectedPublisher"
      @delete="onDeleteCreative"
      @edit="onEditCreative"
    />
  </div>
</template>

<script>
import PublisherSelector from '@/components/organisms/modules/ecommerce/PublisherSelector';

import CREATIVE_HTML_SIZES from '@/model/modules/ecommerce/offer/creative/creativeHtml/CreativeHtmlSizes';
import AsterixInput from '@/components/atoms/AsterixInput';
import CreativeHtml from '@/model/modules/ecommerce/offer/creative/creativeHtml/CreativeHtml';
import FormRow from '@/components/atoms/FormRow/FormRow';
import CodeEditor from '@/components/organisms/shared/CodeEditor';
import { creatives } from '@/services/modules/ecommerce/offer';
import { Toast } from '@/model/shared/Toast';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import CreativeListHtml from '@/components/organisms/modules/ecommerce/offer/creatives/CreativeListHtml';

export default {
  name: 'CreativeHtml',
  components: {
    CreativeListHtml,
    FormRow,
    AsterixInput,
    PublisherSelector,
    CodeEditor,
    CreativeModalFooter: () => import('@/components/molecules/shared/AsterixFooterModal'),
    AsterixFormModal,
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    defaultPublisher: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      allCreatives: null,
      modalLoading: false,
    };
  },
  computed: {
    availableSizes() {
      return Object.values(CREATIVE_HTML_SIZES);
    },
  },
  watch: {
    offer: {
      deep: true,
      handler() {
        this.loadCreatives();
      },
    },
  },
  created() {
    this.creative = new CreativeHtml();
    this.utms.html = this.offer.utms.html;
    this.selectedPublisher = this.defaultPublisher;
    this.loadCreatives();
  },
  methods: {
    loadCreatives() {
      this.allCreatives = this.offer.creatives[CREATIVE_TYPES.HTML.id] || [];
      this.isLoadingUpdateOffer = false;
    },
    async onFormSubmit({ valid }) {
      if (valid) {
        this.modalLoading = true;
        if (this.isEditing) {
          const updateHtml = creatives.html.update;
          await updateHtml(this.offer?.id, this.creative);

          this.updateLocalCreative();

          this.createToast(Toast.success('Creative updated', `Creative updated successfully!`));
        } else {
          const createHtml = creatives.html.create;
          const { data: id } = await createHtml(this.offer?.id, this.creative);

          if (id) {
            this.creative.id = id;
            this.createToast(Toast.success('Creative created', `Creative created successfully!`));
          }
        }
        this.modalLoading = false;
        this.isLoadingUpdateOffer = true;
        await this.debounceUpdate();
        this.closeModal();
      }
    },
    async onDeleteCreative(creative) {
      try {
        this.createDefaultLoadingToast('deleted');
        this.isLoadingUpdateOffer = true;
        const removeHtml = creatives.html.remove;
        await removeHtml(this.offer?.id, creative?.id);
        await this.debounceUpdate();
        this.setSuccessLoadingToast();
      } catch {
        this.setErrorLoadingToast();
      }
    },
    closeModal() {
      this.showFormModal = false;
      this.creative = new CreativeHtml();
    },
    updateLocalCreative() {
      const updateCrea = this.allCreatives.find(crea => crea.id === this.creative.id);
      updateCrea.size = this.creative.size;
      updateCrea.name = this.creative.name;
      updateCrea.code = this.creative.code;
      updateCrea.keepHtmlLinks = this.creative.keepHtmlLinks;
    },
  },
};
</script>

<style scoped>
::v-deep .code-editor {
  min-height: 140px;
  max-height: 33vh;
}

::v-deep .asterix-modal > div.bg-white.overflow-hidden {
  min-width: 66vw;
}
</style>
