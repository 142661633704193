var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "card-form",
        [
          _c("template", { slot: "title" }, [_vm._v("Publisher List")]),
          _c(
            "template",
            { slot: "form" },
            [
              _c(
                "div",
                { staticClass: "flex justify-end mb-6" },
                [
                  _c(
                    "sun-button",
                    {
                      staticClass:
                        "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                      attrs: {
                        variant: "pill",
                        color: "gray",
                        disabled: !_vm.baseOffer,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showAddPublisherModal = true
                        },
                      },
                    },
                    [_vm._v(" + Add publisher ")]
                  ),
                ],
                1
              ),
              _vm.baseOffer
                ? _c("sun-data-table", {
                    staticClass: "w-full",
                    attrs: {
                      headers: _vm.availableHeaders,
                      content: _vm.rows,
                      striped: "",
                      hoverable: "",
                      "no-sticky-headers": "",
                      loading: _vm.isLoading,
                      empty: !_vm.hasData,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `col.directLink`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "sun-data-table-cell",
                                { staticClass: "text-left max-w-col" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col justify-start truncate",
                                    },
                                    [
                                      item.directLink
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cursor-pointer flex",
                                              on: {
                                                click: function ($event) {
                                                  _vm.entityPixelToShow = {
                                                    name: "Direct Link",
                                                    pixel: item.directLink,
                                                  }
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "truncate" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.directLink)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-orange-600",
                                                },
                                                [
                                                  _c("eye-svg", {
                                                    staticClass: "w-4 h-4",
                                                    attrs: {
                                                      title:
                                                        "Show Direct Link Modal",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "text-center" },
                                            [_vm._v("N/A")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `col.impressionCode`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "sun-data-table-cell",
                                { staticClass: "text-left max-w-col" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col justify-start truncate",
                                    },
                                    [
                                      item.impressionCode
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cursor-pointer flex",
                                              on: {
                                                click: function ($event) {
                                                  _vm.entityPixelToShow = {
                                                    name: "Direct Link",
                                                    pixel: item.impressionCode,
                                                  }
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "truncate" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.impressionCode)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-orange-600",
                                                },
                                                [
                                                  _c("eye-svg", {
                                                    staticClass: "w-4 h-4",
                                                    attrs: {
                                                      title:
                                                        "Show Direct Link Modal",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "text-center" },
                                            [_vm._v("N/A")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `col.toggle`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "sun-data-table-cell",
                                { staticClass: "text-left" },
                                [
                                  _c("sun-toggle", {
                                    key: `${item.toggle}_${_vm.forceToggleRender}`,
                                    attrs: {
                                      "checked-value": _vm.config.ACTIVE,
                                      "unchecked-value": _vm.config.INACTIVE,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateStatus(item)
                                      },
                                    },
                                    model: {
                                      value: item.toggle,
                                      callback: function ($$v) {
                                        _vm.$set(item, "toggle", $$v)
                                      },
                                      expression: "item.toggle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `col.test`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "sun-data-table-cell",
                                { staticClass: "text-left" },
                                [
                                  _c("sun-toggle", {
                                    key: `${item.test}_${_vm.forceToggleRender}`,
                                    attrs: {
                                      "checked-value": true,
                                      "unchecked-value": false,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changePixelTest(item)
                                      },
                                    },
                                    model: {
                                      value: item.test,
                                      callback: function ($$v) {
                                        _vm.$set(item, "test", $$v)
                                      },
                                      expression: "item.test",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `col.publisher`,
                          fn: function ({ item }) {
                            return [
                              _c("sun-data-table-cell", [
                                _vm._v(" " + _vm._s(item.publisher.name) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `col.action`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "sun-data-table-cell",
                                { staticClass: "actions" },
                                [
                                  _c("table-action-menu", {
                                    attrs: { actions: _vm.availableActions },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onActionClick($event, item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _c("asterix-no-data", {
                                staticClass: "bg-white",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "block text-center",
                                            },
                                            [
                                              _vm._v(
                                                " There isn't any publisher related to this offer "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "subtitle",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-center",
                                            },
                                            [
                                              _c("p", [
                                                _vm._v("You can add one now"),
                                              ]),
                                              _c(
                                                "sun-button",
                                                {
                                                  staticClass:
                                                    "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 ml-4 text-center",
                                                  attrs: {
                                                    variant: "pill",
                                                    color: "gray",
                                                    disabled: !_vm.baseOffer,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showAddPublisherModal = true
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Add publisher ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1473012807
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _c("div", {
                    staticClass:
                      "w-full h-32 animate-pulse bg-gray-300 rounded",
                  }),
            ],
            1
          ),
        ],
        2
      ),
      _vm.entityPixelToShow
        ? _c("copy-pixel-modal", {
            attrs: { value: _vm.entityPixelToShow },
            on: {
              cancel: function ($event) {
                _vm.entityPixelToShow = null
              },
            },
          })
        : _vm._e(),
      _vm.showAddPublisherModal
        ? _c("add-publisher-modal", {
            attrs: {
              "offer-publisher": _vm.offerPublisherSelected,
              publishers: _vm.alreadyAddedPublishers,
              "offer-publishers": _vm.baseOffer.publishers,
              "is-percentage-offer": _vm.isPercentageOffer,
            },
            on: {
              confirm: _vm.confirmAddPublisher,
              cancel: function ($event) {
                _vm.showAddPublisherModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }