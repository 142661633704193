<template>
  <div>
    <asterix-form-modal
      v-if="showFormModal"
      :title="creativeFormTitle"
      closable
      @submit="onFormSubmit"
      @cancel="closeModal"
    >
      <template #content>
        <form-row>
          <sun-label-group class="w-full" text="Title">
            <asterix-input
              id="name"
              v-model="creative.name"
              type="text"
              name="name"
              validate-on-blur
              :minlength="3"
              :maxlength="50"
              text-error="the field is required."
              placeholder="My amazing creative"
              required="required"
            />
          </sun-label-group>
        </form-row>

        <form-row>
          <url-creator
            v-if="creative"
            v-model="creative.targetUrl"
            :options="urlCreatorOptions"
            class="w-full"
            label="Target URL"
            required
          />
        </form-row>
      </template>
      <template #footer>
        <creative-modal-footer :loading="modalLoading" @cancel="closeModal" />
      </template>
    </asterix-form-modal>

    <div class="text-right">
      <sun-button variant="pill" class="custom-p-1 text-xs" @click="showFormModal = true">+ new creative</sun-button>
    </div>

    <form-row v-if="allCreatives.length">
      <sun-label-group class="w-full mt-8" text="Select publisher to get its code">
        <publisher-selector v-model="selectedPublisher" :publishers="publishers"></publisher-selector>
      </sun-label-group>
    </form-row>

    <creative-list-textlink
      :key="allCreatives.length"
      class="mt-8"
      show-actions
      :offer="offer"
      :is-loading="isLoadingUpdateOffer"
      :creatives="allCreatives"
      :is-copy-available="isSelectedPublisher"
      @delete="onDeleteCreative"
      @edit="onEditCreative"
    />
  </div>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import UrlCreator from '@/components/molecules/shared/UrlCreator/UrlCreator';
import PublisherSelector from '@/components/organisms/modules/ecommerce/PublisherSelector';
import { creatives } from '@/services/modules/ecommerce/offer';
import { Toast } from '@/model/shared/Toast';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import CreativeTextlink from '@/model/modules/ecommerce/offer/creative/creativeTextlink/CreativeTextlink';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import CreativeListTextlink from '@/components/organisms/modules/ecommerce/offer/creatives/CreativeListTextlink';
import CONFIG from '@/components/organisms/modules/ecommerce/offer/form/config';

export default {
  name: 'CreativeTextlink',
  components: {
    CreativeListTextlink,
    UrlCreator,
    FormRow,
    AsterixInput,
    PublisherSelector,
    CreativeModalFooter: () => import('@/components/molecules/shared/AsterixFooterModal'),
    AsterixFormModal,
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    defaultPublisher: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      urlCreatorOptions: CONFIG.urlCreatorOptions,
      allCreatives: null,
      modalLoading: false,
    };
  },
  watch: {
    offer: {
      deep: true,
      handler() {
        this.loadCreatives();
      },
    },
  },
  created() {
    this.creative = new CreativeTextlink();
    this.utms.textlink = this.offer.utms.textlink;
    this.selectedPublisher = this.defaultPublisher;
    this.loadCreatives();
  },
  methods: {
    loadCreatives() {
      this.allCreatives = this.offer.creatives[CREATIVE_TYPES.TEXTLINK.id] || [];
      this.isLoadingUpdateOffer = false;
    },
    async onDeleteCreative(creative) {
      try {
        this.createDefaultLoadingToast('deleted');
        const removeTextlink = creatives.textlink.remove;
        await removeTextlink(this.offer?.id, creative?.id);
        this.isLoadingUpdateOffer = true;
        await this.debounceUpdate();
        this.setSuccessLoadingToast();
      } catch {
        this.setErrorLoadingToast();
      }
    },
    async onFormSubmit({ valid }) {
      if (valid) {
        this.modalLoading = true;
        if (this.isEditing) {
          const updateTextlink = creatives.textlink.update;
          await updateTextlink(this.offer?.id, this.creative);

          this.updateLocalCreative();

          this.createToast(Toast.success('Creative updated', `Creative updated successfully!`));
        } else {
          const createTextlink = creatives.textlink.create;
          const { data: id } = await createTextlink(this.offer?.id, this.creative);

          if (id) {
            this.creative.id = id;
            this.createToast(Toast.success('Creative created', `Creative created successfully!`));
          }
        }
        this.modalLoading = false;
        this.isLoadingUpdateOffer = true;
        await this.debounceUpdate();
        this.closeModal();
      }
    },
    closeModal() {
      this.showFormModal = false;
      this.creative = new CreativeTextlink();
    },
    updateLocalCreative() {
      const updateCrea = this.allCreatives.find(crea => crea.id === this.creative.id);
      updateCrea.name = this.creative.name;
      updateCrea.targetUrl = this.creative.targetUrl;
    },
  },
};
</script>

<style scoped>
::v-deep .url-creator-add,
::v-deep .url-creator-params {
  @apply px-4;
}
::v-deep .asterix-modal > div.bg-white.overflow-hidden {
  min-width: 66vw;
}
</style>
