import { render, staticRenderFns } from "./NoImplementedYet.vue?vue&type=template&id=3bf6e6da&scoped=true&functional=true&"
import script from "./NoImplementedYet.vue?vue&type=script&lang=js&"
export * from "./NoImplementedYet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "3bf6e6da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bf6e6da')) {
      api.createRecord('3bf6e6da', component.options)
    } else {
      api.rerender('3bf6e6da', component.options)
    }
    module.hot.accept("./NoImplementedYet.vue?vue&type=template&id=3bf6e6da&scoped=true&functional=true&", function () {
      api.rerender('3bf6e6da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/modules/ecommerce/offer/form/NoImplementedYet.vue"
export default component.exports