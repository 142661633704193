import { mapActions } from 'vuex';
import { debounce } from 'lodash/function';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import {
  SET_LOADING_TOAST,
  SET_STATE_ERROR_TOAST,
  SET_STATE_LOADING_TOAST,
  SET_STATE_SUCCESS_TOAST,
} from '@/store/modules/loadingToast/keys';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';
import { LoadingToast } from '@/entities/shared/LoadingToast';
import apiRequest from '@/utils/apiRequest';
import { updateUtm as utmDeepLink } from '@/services/modules/ecommerce/offer/creatives/deeplink/updateUtm';
import { Toast } from '@/model/shared/Toast';
import Utms from '@/entities/ecommerce/Utms';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import { getAllTrackerLinks } from '@/services/modules/ecommerce/offer/creatives/deeplink/getAllTrackerLinks';

export const creativeFormMixin = {
  data() {
    return {
      creative: null,
      creativeTypes: CREATIVE_TYPES,
      created: [],
      removed: [],
      showFormModal: false,
      selectedPublisher: null,
      loadingToast: null,
      utmButtonLoading: false,
      utms: new Utms(),
      utmError: null,
      isLoadingLinkTracker: false,
      isLoadingUpdateOffer: false,
    };
  },
  computed: {
    publishers() {
      return this.offer?.publishers.map(p => p.publisher);
    },
    isEditing() {
      return !!this.creative?.id;
    },
    creativeFormTitle() {
      return this.isEditing ? 'Edit creative' : 'New creative';
    },
    isSelectedPublisher() {
      return !!this.selectedPublisher;
    },
  },
  watch: {
    selectedPublisher(newPublisher) {
      this.allCreatives?.forEach(crea => (crea.publisher = newPublisher));
      this.$emit('updatePublisher', newPublisher);
      if (!newPublisher) {
        this.$emit('deepLinkTracker', undefined);
      }
      if (newPublisher && newPublisher !== this.defaultPublisher) {
        this.callDeepLinkTracker();
      }
    },
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      setPropsLoadingToast: SET_LOADING_TOAST,
      setLoadingPropToast: SET_STATE_LOADING_TOAST,
      setSuccessLoadingToast: SET_STATE_SUCCESS_TOAST,
      setErrorLoadingToast: SET_STATE_ERROR_TOAST,
    }),
    onEditCreative(creative) {
      this.creative = creative.clone();
      this.showFormModal = true;
    },

    setLoadingToast(creative) {
      this.loadingToast = new LoadingToast('link', creative, false, false, LOADING_TOAST_STATUS.LOADING);
      this.loadingToast.textSuccess = 'Entity tracker link copied!';
      this.loadingToast.textLoading = 'The entity tracker link is being created ';
      this.loadingToast.textError = 'Entity tracker not copied... ';
      this.showToast();
    },
    createDefaultLoadingToast(type) {
      this.loadingToast = new LoadingToast('link', {}, false, false, LOADING_TOAST_STATUS.LOADING);
      this.loadingToast.textSuccess = `Creative ${type} correctly`;
      this.loadingToast.textLoading = `The creative is being ${type}`;
      this.loadingToast.textError = `Creative dont ${type}...`;
      this.showToast();
    },
    async updateUtms(offerId, type) {
      this.utmButtonLoading = true;
      this.utmError = null;
      await apiRequest(async () => {
        switch (type) {
          case this.creativeTypes.DEEPLINK.value:
            await utmDeepLink(offerId, this.utms[type]);
            this.createToast(Toast.success('UTMs updated', 'UTMs updated successfully!'));
            break;
          default:
            this.createToast(
              Toast.error('UTMs updated not implemented', 'This type of creative dont have UTMs implemented')
            );
            break;
        }
      }).catch(error => {
        this.createToast(Toast.error('UTMs not updated', error));
        this.utmError = 'Utms must start with "?" and must be a valid query string';
      });
      if (this.selectedPublisher) await this.callDeepLinkTracker();
      this.utmButtonLoading = false;
    },
    async callDeepLinkTracker() {
      if (!this.selectedPublisher) return null;
      this.isLoadingLinkTracker = true;
      const data = await getAllTrackerLinks(this.offer.id, this.selectedPublisher.id);
      this.$emit('deepLinkTracker', data);
      this.isLoadingLinkTracker = false;
    },

    loadDeepLinkTracker(codes) {
      if (!this.creative || !codes || this.creative.type.id !== this.creativeTypes.DEEPLINK.id) return null;
      Object.entries(codes).forEach(keyValue => {
        const [key, value] = keyValue;
        const creative = this.allCreatives.find(crea => crea.id === key);
        if (creative) creative.directLink = value;
      });
    },
    showToast() {
      this.setPropsLoadingToast(this.loadingToast);
    },
    debounceUpdate: debounce(function () {
      this.updateOffer();
    }, 1000),
    updateOffer() {
      this.$emit('change');
    },
  },
};
