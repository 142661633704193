var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full text-sm" },
    [
      _c(
        "card-form",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_vm._v("History")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "form" },
            [
              _c("sun-data-table", {
                staticClass: "w-full m-2 max-height-25 overflow-auto",
                attrs: {
                  headers: _vm.entity.headers,
                  content: _vm.entity.data,
                  striped: "",
                  hoverable: "",
                  loading: _vm.isLoading,
                },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("asterix-no-data", {
                          staticClass: "bg-white text-center",
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }