<template>
  <div>
    <card-form>
      <template slot="title">Publisher List</template>
      <template slot="form">
        <div class="flex justify-end mb-6">
          <sun-button
            variant="pill"
            class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
            color="gray"
            :disabled="!baseOffer"
            @click="showAddPublisherModal = true"
          >
            + Add publisher
          </sun-button>
        </div>
        <sun-data-table
          v-if="baseOffer"
          :headers="availableHeaders"
          :content="rows"
          striped
          hoverable
          no-sticky-headers
          :loading="isLoading"
          :empty="!hasData"
          class="w-full"
        >
          <template #[`col.directLink`]="{ item }">
            <sun-data-table-cell class="text-left max-w-col">
              <div class="flex flex-col justify-start truncate">
                <div
                  v-if="item.directLink"
                  class="cursor-pointer flex"
                  @click="entityPixelToShow = { name: 'Direct Link', pixel: item.directLink }"
                >
                  <span class="truncate">{{ item.directLink }}</span>
                  <span class="text-orange-600"><eye-svg class="w-4 h-4" title="Show Direct Link Modal" /></span>
                </div>
                <span v-else class="text-center">N/A</span>
              </div>
            </sun-data-table-cell>
          </template>
          <template #[`col.impressionCode`]="{ item }">
            <sun-data-table-cell class="text-left max-w-col">
              <div class="flex flex-col justify-start truncate">
                <div
                  v-if="item.impressionCode"
                  class="cursor-pointer flex"
                  @click="entityPixelToShow = { name: 'Direct Link', pixel: item.impressionCode }"
                >
                  <span class="truncate">{{ item.impressionCode }}</span>
                  <span class="text-orange-600"><eye-svg class="w-4 h-4" title="Show Direct Link Modal" /></span>
                </div>
                <span v-else class="text-center">N/A</span>
              </div>
            </sun-data-table-cell>
          </template>

          <template #[`col.toggle`]="{ item }">
            <sun-data-table-cell class="text-left">
              <sun-toggle
                :key="`${item.toggle}_${forceToggleRender}`"
                v-model="item.toggle"
                :checked-value="config.ACTIVE"
                :unchecked-value="config.INACTIVE"
                @change="updateStatus(item)"
              />
            </sun-data-table-cell>
          </template>
          <template #[`col.test`]="{ item }">
            <sun-data-table-cell class="text-left">
              <sun-toggle
                :key="`${item.test}_${forceToggleRender}`"
                v-model="item.test"
                :checked-value="true"
                :unchecked-value="false"
                @change="changePixelTest(item)"
              />
            </sun-data-table-cell>
          </template>
          <template #[`col.publisher`]="{ item }">
            <sun-data-table-cell>
              {{ item.publisher.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.action`]="{ item }">
            <sun-data-table-cell class="actions">
              <table-action-menu :actions="availableActions" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>
          <template #empty>
            <asterix-no-data class="bg-white">
              <template #title>
                <span class="block text-center"> There isn't any publisher related to this offer </span>
              </template>
              <template #subtitle>
                <div class="flex justify-center">
                  <p>You can add one now</p>
                  <sun-button
                    variant="pill"
                    class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 ml-4 text-center"
                    color="gray"
                    :disabled="!baseOffer"
                    @click="showAddPublisherModal = true"
                  >
                    Add publisher
                  </sun-button>
                </div>
              </template>
            </asterix-no-data>
          </template>
        </sun-data-table>
        <div v-else class="w-full h-32 animate-pulse bg-gray-300 rounded" />
      </template>
    </card-form>
    <copy-pixel-modal v-if="entityPixelToShow" :value="entityPixelToShow" @cancel="entityPixelToShow = null" />
    <add-publisher-modal
      v-if="showAddPublisherModal"
      :offer-publisher="offerPublisherSelected"
      :publishers="alreadyAddedPublishers"
      :offer-publishers="baseOffer.publishers"
      :is-percentage-offer="isPercentageOffer"
      @confirm="confirmAddPublisher"
      @cancel="showAddPublisherModal = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import CardForm from '@/components/atoms/CardForm';
import CONFIG from './config';
import { updatePublisherStatus } from '@/services/modules/ecommerce/offer/updatePublisherStatus';
import { addPublisher, updatePublisher } from '@/services/modules/ecommerce/offer';
import percentage from '@/filters/percentage';
import Column from '@/model/shared/Column';
import { getOfferCreativesJson } from '@/services/modules/ecommerce/offer/getOfferCreativesJson';
import {
  SET_LOADING_TOAST,
  SET_STATE_ERROR_TOAST,
  SET_STATE_LOADING_TOAST,
  SET_STATE_SUCCESS_TOAST,
} from '@/store/modules/loadingToast/keys';
import { LoadingToast } from '@/entities/shared/LoadingToast';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';
import apiRequest from '@/utils/apiRequest';
import { COMMISSION_TYPES } from '@/model/modules/ecommerce/publisher/CommissionTypes';
import EyeSvg from '@/components/icons/EyeSvg';
import currency from '@/filters/currency';
import OfferPublisher from '@/entities/ecommerce/OfferPublisher';
import { COMMISSION_OWNERS } from '@/model/modules/ecommerce/publisher/CommissionOwners';

export default {
  name: 'Publishers',
  components: {
    EyeSvg,
    AddPublisherModal: () => import('@/components/organisms/modules/ecommerce/offer/form/publishers/AddPublisherModal'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    CopyPixelModal: () => import('@/components/organisms/modules/ecommerce/CopyPixelModal'),

    CardForm,
  },
  props: {
    baseOffer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config: CONFIG,
      headers: [
        new Column(CONFIG.tableHeaders.ACTIVE, 'toggle'),
        new Column(CONFIG.tableHeaders.PIXEL_TEST, 'test'),
        new Column(CONFIG.tableHeaders.PUBLISHER, 'publisher'),
        new Column(CONFIG.tableHeaders.DIRECT_LINK, 'directLink'),
        new Column(CONFIG.tableHeaders.IMPRESSION_CODE, 'impressionCode'),
        new Column(CONFIG.tableHeaders.PUBLISHER_PRICE, 'publisher_price'),
        new Column(CONFIG.tableHeaders.PUBLISHER_COMISSION, 'publisher_price'),
        new Column(CONFIG.tableHeaders.OUR_COMMISSION, 'our_commission'),
        new Column(CONFIG.tableHeaders.ACTION, 'action'),
      ],
      rows: [],
      isLoading: false,
      hasData: true,
      forceToggleRender: 0,
      showAddPublisherModal: false,
      entityPixelToShow: null,
      previousPublisherStatus: {
        id: null,
        status: null,
      },
      toggleValue: true,
      actions: [
        { id: 'export', name: 'Export creatives' },
        { id: 'edit', name: 'Edit' },
      ],
      clipboardText: '',
      offerPublisherSelected: null,
    };
  },
  computed: {
    alreadyAddedPublishers() {
      return (
        this.baseOffer?.publishers?.map(offerPublisher => {
          return offerPublisher.publisher;
        }) || []
      );
    },
    hasCreatives() {
      return !!Object.values(this.baseOffer?.creatives).find(creativesByType => creativesByType?.length);
    },
    availableActions() {
      return this.actions.filter(action => this.hasCreatives || action.id !== 'export');
    },
    isPercentageOffer() {
      return (
        this.baseOffer.priceType === this.config.percentage.TYPE &&
        this.baseOffer.priceValueType === this.config.percentage.VALUE_TYPE
      );
    },
    availableHeaders() {
      const headerNotAvailable = this.isPercentageOffer
        ? CONFIG.tableHeaders.PUBLISHER_PRICE
        : CONFIG.tableHeaders.PUBLISHER_COMISSION;

      return this.headers.filter(col => col.title !== headerNotAvailable);
    },
  },
  watch: {
    baseOffer: {
      deep: true,
      handler() {
        this.setTableRows();
      },
    },
    showAddPublisherModal(value) {
      if (!value) this.offerPublisherSelected = null;
    },
  },
  created() {
    if (this.baseOffer) {
      this.setTableRows();
    }
  },
  methods: {
    ...mapActions({
      setPropsLoadingToast: SET_LOADING_TOAST,
      setLoadingPropToast: SET_STATE_LOADING_TOAST,
      createToast: CREATE_TOAST,
      setSuccessLoadingToast: SET_STATE_SUCCESS_TOAST,
      setErrorLoadingToast: SET_STATE_ERROR_TOAST,
    }),
    showClipboardText(item) {
      return item.url !== this.clipboardText ? 'Copy to clipboard' : 'Copied';
    },
    setTableRows() {
      this.rows = [];
      this.baseOffer.publishers.forEach(offerPublisher => {
        let publisherPrice;
        if (!offerPublisher.publisherPrice) publisherPrice = 'N/A';
        else if (this.isPercentageOffer) publisherPrice = percentage(offerPublisher.publisherPrice);
        else publisherPrice = currency(offerPublisher.publisherPrice, this.baseOffer.currency.id);
        this.rows.push({
          id: offerPublisher.id,
          toggle: offerPublisher.status,
          test: offerPublisher.test,
          publisher: offerPublisher.publisher,
          URL: offerPublisher.publisher.postBackUrl,
          publisher_price: publisherPrice,
          our_commission: percentage(offerPublisher.ourCommission),
          commissionValue:
            offerPublisher.commissionType === COMMISSION_TYPES.FIX_PRICE
              ? offerPublisher.publisherPrice
              : offerPublisher.ourCommission,
          commissionType: offerPublisher.commissionType,
          directLink: offerPublisher.directLink,
          impressionCode: offerPublisher.impressionCode,
          subPublishersLockedList: offerPublisher.subPublishersLocked,
        });
      });
    },
    async updateStatus(value) {
      try {
        const offerId = this.baseOffer.id;
        await updatePublisherStatus(offerId, value);
        const toast = Toast.success('Success!', `Publisher ${value.publisher.name} has been updated`);
        this.createToast(toast);
        this.baseOffer.publishers.forEach(publisher => {
          if (publisher.id === value.id) {
            publisher.status = value.toggle;
          }
        });
      } catch (error) {
        const toast = Toast.warning('Error!', `Publisher ${value.publisher.name} has not been updated`);
        this.createToast(toast);
        this.rows.forEach(r => {
          if (r.id === this.previousPublisherStatus.id) {
            r.toggle = this.previousPublisherStatus.status;
            this.forceToggleRender++;
          }
        });
        throw error;
      }
    },
    async changePixelTest(item) {
      this.offerPublisherSelected = item;
      let commissionOwner = COMMISSION_OWNERS.NONE;
      if (item.commissionType === COMMISSION_TYPES.FIX_PRICE) {
        commissionOwner = COMMISSION_OWNERS.PUBLISHER;
      } else if (item.commissionType === COMMISSION_TYPES.PERCENT) {
        commissionOwner = COMMISSION_OWNERS.OUR;
      }
      await this.confirmAddPublisher(
        new OfferPublisher(
          item.id,
          item.commissionValue,
          item.commissionType,
          commissionOwner,
          item.ourCommission,
          item.publisherPrice,
          item.publisher.id,
          item.toggle,
          item.directLink,
          item.impressionCode,
          item.test,
          item.subPublishersLocked
        )
      );
      this.offerPublisherSelected = null;
    },
    async confirmAddPublisher(publisher) {
      this.showAddPublisherModal = false;

      if (this.offerPublisherSelected) {
        await this.updatePublisher(publisher);
      } else {
        await this.createPublisher(publisher);
      }

      this.$emit('reload');
    },
    async onActionClick(action, item) {
      switch (action.id) {
        case 'export':
          await this.exportCreative(item);
          break;
        case 'edit':
          this.goToEditPublisher(item);
          break;
      }
    },
    async exportCreative(offerPublisher) {
      if (!this.hasCreatives) return;

      const loadingToast = new LoadingToast('link', {}, false, false, LOADING_TOAST_STATUS.LOADING);
      loadingToast.textSuccess = 'Creatives exported successfully';
      loadingToast.textLoading = 'Creatives are being exported';
      loadingToast.textError = 'Error while exporting creatives';
      this.setPropsLoadingToast(loadingToast);

      await apiRequest(async () => {
        await getOfferCreativesJson(this.baseOffer.id, offerPublisher.publisher.id);
        this.setSuccessLoadingToast();
      }).catch(() => {
        this.setErrorLoadingToast();
      });
    },
    goToEditPublisher(item) {
      this.offerPublisherSelected = item;
      this.showAddPublisherModal = true;
    },
    async createPublisher(publisher) {
      this.isLoading = true;
      try {
        const offerId = this.baseOffer.id;
        await addPublisher(offerId, publisher);
        this.createToast(Toast.success('Publisher added', 'The publisher has been added successfully'));
      } catch (error) {
        this.createToast(Toast.error('Publisher not added', error.message));
      } finally {
        this.isLoading = false;
      }
    },
    async updatePublisher(publisher) {
      this.isLoading = true;
      try {
        const offerId = this.baseOffer.id;
        await updatePublisher(offerId, publisher, this.offerPublisherSelected.id);
        this.createToast(Toast.success('Publisher updated', 'The publisher has been updated successfully'));
      } catch (error) {
        this.createToast(Toast.error('Publisher not updated', error.message));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .actions .v-popover {
  @apply mx-auto;
}

::v-deep .max-w-col {
  max-width: 250px !important;
}
</style>
