import { api } from '.';
import { DOWNLOAD_CREATIVE_RESOURCE, OFFER_RESOURCE, PUBLISHER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import fileDownload from '@/utils/fileDownload';

export const getResources = (offerId, publisherId) => ({
  [OFFER_RESOURCE]: offerId,
  [PUBLISHER_RESOURCE]: publisherId,
  [DOWNLOAD_CREATIVE_RESOURCE]: null,
});

/**
 *
 * @param {string} offerId
 * @param {string} publisherId
 * @return {Promise<{}>}
 */
export async function getOfferCreativesJson(offerId, publisherId) {
  const partialUrl = api.createUrl(getResources(offerId, publisherId));
  const params = new QueryParamsBuilder();

  const { data } = await api.get(partialUrl, params.buildWithoutPage(), {
    preventCancel: false,
    responseType: 'arraybuffer',
  });
  return fileDownload(data, `creatives-${offerId}-${publisherId}`, 'application/zip');
}
