var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.baseOffer
        ? _c("card-form", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Create your creative")]
                  },
                  proxy: true,
                },
                {
                  key: "form",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Select a creative type" } },
                        [
                          _c("sun-select", {
                            key: _vm.creativeTypeKey,
                            attrs: {
                              "disable-selected-options": "",
                              required: "required",
                              label: "name",
                              "track-by": "id",
                              options: _vm.creativeTypes,
                            },
                            model: {
                              value: _vm.creativeType,
                              callback: function ($$v) {
                                _vm.creativeType = $$v
                              },
                              expression: "creativeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.creativeType
                        ? _c("creative-factory", {
                            staticClass: "mt-8",
                            attrs: {
                              offer: _vm.baseOffer,
                              type: _vm.creativeType,
                            },
                            on: { change: _vm.updateOffer },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2058028756
            ),
          })
        : _c("card-form-loading", { attrs: { rows: 2 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }