<template>
  <card-form>
    <template slot="title">Basic Info</template>
    <template slot="form">
      <div class="w-full flex flex-wrap text-left">
        <div class="w-full sm:w-1/2 px-10">
          <p class="subtitle">Basic Info</p>
          <detail-item :is-loading="isLoading" title="Offer status">
            <template v-if="!isLoading" #text>{{ offer.status || noData }}</template>
          </detail-item>
          <detail-item :is-loading="isLoading" title="Advertiser">
            <template v-if="!isLoading" #text>{{ offer.advertiser.name }}</template>
          </detail-item>
          <detail-item :is-loading="isLoading" title="Tags">
            <template #text>{{ offerTags || noData }}</template>
          </detail-item>
          <detail-item :is-loading="isLoading" title="Time line">
            <template v-if="!isLoading" #text>
              {{ offer.start | applyDateFormatter }} - {{ offer.end | applyDateFormatter }}
            </template>
          </detail-item>

          <p class="subtitle">Attribution</p>
          <detail-item :is-loading="isLoading" title="Postclick">
            <template #text>{{ postClickActive }}</template>
          </detail-item>
          <detail-item v-if="offer.offerAttribution.postclick_active" :is-loading="isLoading" title="Postclick value">
            <template #text>{{ postClickValue || noData }}</template>
          </detail-item>
          <detail-item :is-loading="isLoading" title="Postview">
            <template #text>{{ postViewActive }}</template>
          </detail-item>
          <detail-item v-if="offer.offerAttribution.postview_active" :is-loading="isLoading" title="Postview value">
            <template #text>{{ postViewValue || noData }}</template>
          </detail-item>

          <p class="subtitle">Direct link</p>
          <asterix-input-and-copy
            class="w-full"
            :is-loading="isLoading"
            title="Offer pixel code"
            :value="publisherDetails.directLink"
          />
        </div>

        <div class="w-full sm:w-1/2 px-10">
          <p class="subtitle">Payment method</p>
          <detail-item :is-loading="isLoading" title="Payment method">
            <template #text>{{ offer.priceType }}</template>
          </detail-item>
          <detail-item :is-loading="isLoading" title="Price">
            <template #text>{{ priceToShow }}</template>
          </detail-item>

          <p class="subtitle">Capping</p>
          <detail-item :is-loading="isLoading" title="Total capping">
            <template #text>{{ offer.cap.total || noData }}</template>
          </detail-item>

          <p class="subtitle">Target</p>
          <detail-item :is-loading="isLoading" title="Target countries">
            <template #text>
              <country-display show-name :iso="offer.country.iso3" class="mr-5" />
            </template>
          </detail-item>
        </div>
        <copy-pixel-modal v-if="entityPixelToShow" :value="entityPixelToShow" @cancel="entityPixelToShow = null" />
      </div>
    </template>
  </card-form>
</template>

<script>
import { mapGetters } from 'vuex';
import DetailItem from '@/components/atoms/DetailItem';
import CountryDisplay from '@/components/molecules/shared/CountryDisplay.vue';
import { applyDateFormatters } from '@/filters/dateFilters';
import { spanishDateFormatter } from '@/model/shared/DateFormatter';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { USER } from '@/store/modules/auth/keys';
import AsterixInputAndCopy from '@/components/atoms/AsterixInputAndCopy';
import CardForm from '@/components/atoms/CardForm';

export default {
  name: 'OfferDetailList',
  components: {
    DetailItem,
    CountryDisplay,
    AsterixInputAndCopy,
    CopyPixelModal: () => import('@/components/organisms/modules/ecommerce/CopyPixelModal'),
    CardForm,
  },
  filters: {
    applyDateFormatter: date => applyDateFormatters(date, spanishDateFormatter),
  },
  props: {
    offer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    publisherDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    noData: 'N/A',
    entityPixelToShow: null,
  }),
  computed: {
    ...mapGetters({
      activeContext: ACTIVE_CONTEXT,
      user: USER,
    }),
    offerTags() {
      if (!this.offer) return '';
      return this.offer.tags.map(tag => tag.name).join(', ');
    },
    postClickActive() {
      if (!this.offer) return '';
      return this.offer.offerAttribution.postclick_active ? 'Active' : 'Inactive';
    },
    postClickValue() {
      if (!this.offer) return '';
      return this.calculateDays(this.offer.offerAttribution.postclick_value);
    },
    postViewActive() {
      if (!this.offer) return '';
      return this.offer.offerAttribution.postview_active ? 'Active' : 'Inactive';
    },
    postViewValue() {
      if (!this.offer) return '';
      return this.calculateDays(this.offer.offerAttribution.postview_value);
    },
    priceToShow() {
      if (!this.offer) return '';
      return `${this.publisherDetails?.price} ${this.offer.currency.name}`;
    },
  },
  methods: {
    calculateDays(time) {
      const HALF_DAY = 0.5;
      const ONE_DAY = 1;
      const DAY_IN_SECONDS = 86400;
      const days = time / DAY_IN_SECONDS;

      if (days < HALF_DAY) return 'less than 12 hours';
      if (days === HALF_DAY) return '12 hours';
      if (days <= ONE_DAY) return '1 day';

      return `${days} days`;
    },
  },
};
</script>

<style scoped>
.section .title {
  @apply font-bold text-xl text-left mb-3;
}

.subtitle {
  @apply text-lg font-semibold text-left mb-4 mt-5;
}
</style>
