var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col w-full" },
    [
      _c("prism-editor", {
        staticClass: "code-editor",
        attrs: {
          highlight: _vm.highlighter,
          "line-numbers": "",
          readonly: _vm.readonly,
        },
        on: {
          blur: function ($event) {
            return _vm.onBlur($event)
          },
          input: function ($event) {
            return _vm.onChange($event)
          },
          focus: function ($event) {
            return _vm.onFocus()
          },
        },
        model: {
          value: _vm.code,
          callback: function ($$v) {
            _vm.code = $$v
          },
          expression: "code",
        },
      }),
      _c("sun-error-text", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.internalError,
            expression: "internalError",
          },
        ],
        attrs: { "show-error": "", "text-error": _vm.textError },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }