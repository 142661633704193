<template>
  <div>
    <overlay>
      <img class="creative-preview-image mx-auto" :src="creative.url" :alt="alt" />
      <template #actions>
        <div class="flex">
          <button v-if="showActions" class="action-button" @click.stop.prevent="$emit('edit', creative)">
            <edit-svg />
          </button>
          <sun-popover class="mx-1 static flex-none" :text-info="clipboardHint">
            <button
              class="action-button"
              :class="{ disabled: !isSelectedPublisher }"
              @click.stop.prevent="$emit('copy', creative)"
            >
              <code-svg />
            </button>
          </sun-popover>
          <button v-if="showActions" class="action-button red" @click.stop.prevent="showDelete">
            <rubbish-bin-svg class="text-white" />
          </button>
        </div>
      </template>
    </overlay>
    <delete-modal :open="showDeleteModal" @confirm="confirmDeleteModal" @cancel="showDeleteModal = false">
      <template #description> You are going to remove the creative. This action can not be undone. </template>
    </delete-modal>
  </div>
</template>

<script>
import CodeSvg from '@/components/icons/CodeSvg';
import Overlay from '@/components/atoms/Overlay';
import RubbishBinSvg from '@/components/icons/RubbishBinSvg';
import EditSvg from '@/components/icons/EditSvg';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'CreativePreviewBannerImage',
  components: {
    CodeSvg,
    Overlay,
    EditSvg,
    DeleteModal,
    RubbishBinSvg,
  },
  props: {
    /** @type CreativeBanner */
    creative: {
      type: Object,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isSelectedPublisher: {
      type: Boolean,
      defalut: () => false,
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  computed: {
    clipboardHint() {
      return this.creative.publisher ? 'Copy to clipboard' : 'You need to pick a publisher first';
    },
  },
  methods: {
    confirmDeleteModal() {
      this.$emit('delete', this.creative);
      this.showDeleteModal = false;
    },
    showDelete() {
      this.showDeleteModal = true;
    },
  },
};
</script>

<style scoped>
.creative-preview-image {
  max-width: 200px;
  max-height: 200px;
}
.action-button:hover {
  @apply bg-gray-200;
}
.action-button.red:hover {
  @apply bg-red-800;
}
.action-button.red {
  @apply bg-red-500;
}
.action-button.disabled {
  @apply bg-gray-400 cursor-not-allowed;
}
.action-button.disabled:hover {
  @apply bg-gray-400;
}
.action-button {
  @apply p-1 rounded bg-white align-bottom;
}
.action-button svg {
  @apply w-5 h-5;
}

::v-deep .overlay {
  min-width: 120px;
}
</style>
