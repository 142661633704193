var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col w-full" },
    [
      _c(
        "div",
        { staticClass: "flex justify-end mb-2 px-2" },
        [
          _c("button-group", {
            staticClass: "mr-4",
            attrs: { options: _vm.displayOpts },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function ({ option }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-baseline" },
                      [
                        option.id === "preview"
                          ? _c("eye-svg", { staticClass: "w-4 h-4 mr-1" })
                          : _c("code-svg", { staticClass: "w-5 h-5 mr-1" }),
                        _c("span", [_vm._v(_vm._s(option.name))]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.displayOptsSeleced,
              callback: function ($$v) {
                _vm.displayOptsSeleced = $$v
              },
              expression: "displayOptsSeleced",
            },
          }),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.copyToClipboard(_vm.value)
                },
              },
            },
            [
              _c(
                "sun-popover",
                { attrs: { "text-info": _vm.clipboardHint } },
                [_c("copy-svg", { staticClass: "h-4 w-4 m-1" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectedView.id === "preview"
        ? _c(
            "div",
            {
              staticClass:
                "border border-dashed border-gray-400 text-center p-4",
            },
            [
              _vm._t("default", function () {
                return [
                  _c("iframe", {
                    staticClass: "w-full",
                    attrs: {
                      srcdoc: _vm.value,
                      sandbox: "allow-forms allow-popups allow-scripts",
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _c("code-editor", {
            attrs: { readonly: _vm.readonly },
            model: {
              value: _vm.internalCode,
              callback: function ($$v) {
                _vm.internalCode = $$v
              },
              expression: "internalCode",
            },
          }),
      _c("sun-input", {
        attrs: {
          value: _vm.value,
          required: _vm.required,
          "text-error": "Field is required",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }