<template>
  <div>
    <creative-list v-if="!isLoading" class="mt-8">
      <creative-textlink-item
        v-for="crea in creatives"
        :key="crea.id"
        :creative="crea"
        :show-actions="showActions"
        :is-selected-publisher="isCopyAvailable"
        @delete="$emit('delete', crea)"
        @edit="$emit('edit', crea)"
        @copy="onCopy"
      />
      <template #no-data-title> There are not <span class="italic">textlink</span> creatives yet </template>
    </creative-list>
    <div v-else>
      <line-loader v-for="crea in creatives" :key="crea.id" class="h-14"></line-loader>
    </div>
  </div>
</template>

<script>
import CreativeTextlinkItem from '@/components/molecules/modules/ecommerce/offer/CreativeTextlinkItem';
import CreativeList from '@/components/molecules/modules/ecommerce/offer/CreativeList';
import { getPublisherTrackerLink } from '@/services/modules/ecommerce/offer/creatives/textlink/getPublisherTrackerLink';
import { copyToClipboard } from '@/utils/copyToClipboard';
import CreativeTextlink from '@/model/modules/ecommerce/offer/creative/creativeTextlink/CreativeTextlink';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import apiRequest from '@/utils/apiRequest';
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'CreativeListTextlink',
  components: {
    CreativeList,
    CreativeTextlinkItem,
    LineLoader,
  },
  mixins: [creativeFormMixin],
  props: {
    offer: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    creatives: {
      type: Array,
      default: () => null,
    },
    isCopyAvailable: {
      type: Boolean,
      defalut: () => false,
    },
  },
  methods: {
    async onCopy(creative) {
      if (!creative.publisher || !this.offer.id) return;
      const textLinkCreative = new CreativeTextlink(creative.id, creative.publisher, creative.name, creative.targetUrl);
      this.setLoadingToast(textLinkCreative);
      await apiRequest(async () => {
        const { data } = await getPublisherTrackerLink(this.offer.id, textLinkCreative);
        copyToClipboard(data);
        this.setSuccessLoadingToast();
      }).catch(() => {
        this.setErrorLoadingToast();
      });
    },
  },
};
</script>
<style scoped>
.h-14 {
  height: 3.6rem;
}
</style>
