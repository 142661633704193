<template functional>
  <p>No Implemented yet</p>
</template>

<script>
export default {
  name: 'NoImplementedYet',
};
</script>

<style scoped></style>
