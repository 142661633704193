import { CREATIVE_FEED_RESOURCE, OFFER_RESOURCE, PUBLISHER_TRACKERLINK_RESOURCE } from '@/services/keys';
import { getPublisherTrackerLinkShared } from '@/services/modules/ecommerce/offer/creatives/shared/getPublisherTrackerLinkShared';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_FEED_RESOURCE]: creativeId,
  [PUBLISHER_TRACKERLINK_RESOURCE]: undefined,
});

/**
 *
 * @param {String} offerId
 * @param {CreativeFeed} creative
 * @returns {Promise.<{data:object[],meta:null}>}
 */
export function getPublisherTrackerLink(offerId, creative) {
  return getPublisherTrackerLinkShared(getResources(offerId, creative.id), creative);
}
