var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.creatives.length
        ? _c(
            "creative-preview",
            { staticClass: "w-full" },
            _vm._l(_vm.previewFilesBySize, function (size, sizeIndex) {
              return _c(
                "div",
                { key: sizeIndex, class: { "mt-8": sizeIndex !== 0 } },
                [
                  _c("header", { staticClass: "text-left" }, [
                    _c("h1", [_vm._v(_vm._s(size.size))]),
                  ]),
                  !_vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        _vm._l(size.items, function (img) {
                          return _c("creative-preview-banner-image", {
                            key: img.url,
                            attrs: {
                              creative: img,
                              alt: "creative image",
                              "show-actions": _vm.showActions,
                              "is-selected-publisher": _vm.isCopyAvailable,
                            },
                            on: {
                              delete: function ($event) {
                                return _vm.$emit("delete", img)
                              },
                              copy: _vm.onCopy,
                              edit: function ($event) {
                                return _vm.$emit("edit", img)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _c(
                        "div",
                        _vm._l(size.items, function (img) {
                          return _c("line-loader", { key: img.url })
                        }),
                        1
                      ),
                ]
              )
            }),
            0
          )
        : _c("asterix-no-data", {
            staticClass:
              "border border-gray-300 rounded-md shadow-md text-center mt-8",
            attrs: { subtitle: "Create one using the button above" },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(" There are not "),
                    _c("span", { staticClass: "italic" }, [_vm._v("banner")]),
                    _vm._v(" creatives yet "),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }