var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.creative
    ? _c(
        "creative-item",
        {
          attrs: {
            creative: _vm.creative,
            "show-actions": _vm.showActions,
            "is-selected-publisher": _vm.isSelectedPublisher,
          },
          on: {
            delete: function ($event) {
              return _vm.$emit("delete", $event)
            },
            edit: function ($event) {
              return _vm.$emit("edit", $event)
            },
            copy: function ($event) {
              return _vm.$emit("copy", $event)
            },
          },
        },
        [
          _c(
            "form-row",
            [
              _vm.creative.size
                ? _c(
                    "sun-label-group",
                    { staticClass: "w-full mb-1", attrs: { text: "Size" } },
                    [
                      _c("asterix-input", {
                        attrs: { type: "text", disabled: "" },
                        model: {
                          value: _vm.creative.size.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.creative.size, "name", $$v)
                          },
                          expression: "creative.size.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "form-row",
            [
              _c(
                "sun-label-group",
                { staticClass: "w-full mb-1", attrs: { text: "Title" } },
                [
                  _c("asterix-input", {
                    attrs: { type: "text", disabled: "" },
                    model: {
                      value: _vm.creative.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.creative, "name", $$v)
                      },
                      expression: "creative.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "form-row",
            [
              _c(
                "sun-label-group",
                { staticClass: "w-full mb-1", attrs: { text: "HTML code" } },
                [
                  _c("code-editor-preview", {
                    attrs: {
                      "is-publisher-role": _vm.isPublisherRole,
                      value: _vm.creative.code,
                      readonly: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isPublisherRole
            ? _c(
                "form-row",
                [
                  _c(
                    "sun-label-group",
                    {
                      staticClass: "w-full",
                      attrs: { text: "Keep HTML links" },
                    },
                    [
                      _c("sun-toggle", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.creative.keepHtmlLinks,
                          callback: function ($$v) {
                            _vm.$set(_vm.creative, "keepHtmlLinks", $$v)
                          },
                          expression: "creative.keepHtmlLinks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isPublisherRole
            ? _c(
                "form-row",
                [
                  _c(
                    "sun-label-group",
                    {
                      staticClass: "w-full mb-1",
                      attrs: { text: "External code" },
                    },
                    [
                      _c("code-editor", {
                        attrs: {
                          value: _vm.creative.externalCode,
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }