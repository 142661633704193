<template>
  <div>
    <creative-banner-modal
      v-if="showFormModal"
      :creative="creativeToUpdate"
      :is-loading="modalLoading"
      @submit="onFormSubmit"
      @cancel="closeModal"
    />

    <div class="text-right">
      <sun-button variant="pill" class="custom-p-1 text-xs" @click="showFormModal = true">+ new creative</sun-button>
    </div>

    <form-row v-if="allCreatives.length">
      <sun-label-group class="w-full" text="Select publisher to get its code">
        <publisher-selector
          v-model="selectedPublisher"
          :publishers="publishers"
          required="required"
        ></publisher-selector>
      </sun-label-group>
    </form-row>

    <form-row>
      <creative-list-banner
        class="w-full"
        show-actions
        :offer="offer"
        :is-loading="isLoadingUpdateOffer"
        :creatives="allCreatives"
        :is-copy-available="isSelectedPublisher"
        @delete="onDelete"
        @edit="onEdit"
      />
    </form-row>
  </div>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import PublisherSelector from '@/components/organisms/modules/ecommerce/PublisherSelector';
import { creatives } from '@/services/modules/ecommerce/offer';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import CreativeListBanner from '@/components/organisms/modules/ecommerce/offer/creatives/CreativeListBanner';

export default {
  name: 'CreativeBanner',
  components: {
    CreativeListBanner,

    PublisherSelector,
    FormRow,

    CreativeBannerModal: () =>
      import('@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeBannerModal.vue'),
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    defaultPublisher: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      creativeToUpdate: null,
      allCreatives: null,
      modalLoading: false,
    };
  },
  watch: {
    allCreatives() {
      this.renderPreview();
    },
    offer: {
      deep: true,
      handler() {
        this.loadCreatives();
      },
    },
  },
  mounted() {
    this.renderPreview();
  },
  created() {
    this.utms.banner = this.offer.utms.banner;
    this.selectedPublisher = this.defaultPublisher;
    this.loadCreatives();
  },
  methods: {
    loadCreatives() {
      this.allCreatives = (this.offer?.creatives[CREATIVE_TYPES.BANNER.id] || []).filter(
        crea => !this.removed.includes(crea.id)
      );
      this.isLoadingUpdateOffer = false;
    },
    async uploadFiles(files) {
      const create = creatives.banner.createOrUpdate;
      const promises = files.map(file => create(this.offer?.id, file, this.creativeToUpdate?.id));

      await Promise.all(promises);
      this.isLoadingUpdateOffer = true;
      await this.debounceUpdate();
    },
    async onDelete(creaImg) {
      try {
        this.createDefaultLoadingToast('deleted');
        this.removed.push(creaImg.id);
        const { remove } = creatives.banner;
        await remove(this.offer?.id, creaImg.id);
        this.setSuccessLoadingToast();
        this.isLoadingUpdateOffer = true;
        this.debounceUpdate();
      } catch {
        this.setErrorLoadingToast();
      }
    },
    renderPreview() {
      this.allCreatives.forEach(crea => {
        const img = new Image();

        img.onload = () => {
          const file = this.allCreatives.find(creative => creative.id === crea.id);
          if (file) {
            this.$set(file, 'width', img.naturalWidth || img.width);
            this.$set(file, 'height', img.naturalHeight || img.height);
          }
        };
        img.src = crea.url;
      });
    },
    closeModal() {
      this.showFormModal = false;
      this.creativeToUpdate = null;
    },
    async onFormSubmit(files) {
      this.modalLoading = true;
      await this.uploadFiles(files);
      this.modalLoading = false;
      this.closeModal();
    },
    onEdit(creative) {
      this.creativeToUpdate = creative.clone();

      this.showFormModal = true;
    },
  },
};
</script>
<style scoped>
::v-deep #dropzone-file-upload {
  @apply pb-8;
}
::v-deep .asterix-modal > div.bg-white.overflow-hidden {
  min-width: 66vw;
}
</style>
