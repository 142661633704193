import Column from '@/model/shared/Column';

export const CONFIG = {
  headers: [
    new Column('DATE', 'date'),
    new Column('USER', 'user'),
    new Column('ACTIVITY', 'activity'),
    new Column('DETAIL', 'activity_detail'),
  ],
};
