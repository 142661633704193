var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showFormModal
        ? _c("asterix-form-modal", {
            attrs: { title: _vm.creativeFormTitle, closable: "" },
            on: { submit: _vm.onFormSubmit, cancel: _vm.closeModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "form-row",
                        [
                          _c(
                            "sun-label-group",
                            { staticClass: "w-full", attrs: { text: "Title" } },
                            [
                              _c("asterix-input", {
                                attrs: {
                                  id: "name",
                                  type: "text",
                                  name: "name",
                                  "validate-on-blur": "",
                                  minlength: 3,
                                  maxlength: 50,
                                  "text-error": "the field is required.",
                                  placeholder: "My amazing creative",
                                  required: "required",
                                },
                                model: {
                                  value: _vm.creative.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.creative, "name", $$v)
                                  },
                                  expression: "creative.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.creative
                            ? _c("url-creator", {
                                staticClass: "w-full",
                                attrs: { label: "Target URL", required: "" },
                                model: {
                                  value: _vm.creative.targetUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.creative, "targetUrl", $$v)
                                  },
                                  expression: "creative.targetUrl",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c("creative-modal-footer", {
                        attrs: { loading: _vm.modalLoading },
                        on: { cancel: _vm.closeModal },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1902904827
            ),
          })
        : _vm._e(),
      _vm.allCreatives.length
        ? _c(
            "form-row",
            [
              _c(
                "sun-label-group",
                {
                  staticClass: "w-full",
                  attrs: { text: "Select publisher to get its code" },
                },
                [
                  _c("publisher-selector", {
                    attrs: { publishers: _vm.publishers },
                    model: {
                      value: _vm.selectedPublisher,
                      callback: function ($$v) {
                        _vm.selectedPublisher = $$v
                      },
                      expression: "selectedPublisher",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex w-full mt-8 flex-col" },
        [
          _c(
            "sun-label-group",
            { staticClass: "w-full", attrs: { text: "Add UTMs" } },
            [
              _c("sun-text-area", {
                attrs: { value: _vm.utms.deeplink, resize: "resize-y" },
                on: { change: _vm.utmsChanges },
              }),
              _c(
                "sun-button",
                {
                  staticClass: "text-xs w-10 ml-2 h-6 self-end",
                  attrs: { loading: _vm.utmButtonLoading },
                  on: {
                    click: function ($event) {
                      return _vm.updateUtms(_vm.offer.id, "deeplink")
                    },
                  },
                },
                [_vm._v(" Add ")]
              ),
            ],
            1
          ),
          _c("sun-error-text", {
            attrs: { "show-error": !!_vm.utmError, "text-error": _vm.utmError },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right mt-8" },
        [
          _c(
            "sun-button",
            {
              staticClass: "custom-p-1 text-xs",
              attrs: { variant: "pill" },
              on: {
                click: function ($event) {
                  _vm.showFormModal = true
                },
              },
            },
            [_vm._v("+ new creative")]
          ),
        ],
        1
      ),
      _c("creative-list-deeplink", {
        key: _vm.allCreatives.length,
        staticClass: "mt-2",
        attrs: {
          "show-actions": "",
          offer: _vm.offer,
          "is-loading": _vm.isLoadingLinkTracker || _vm.isLoadingUpdateOffer,
          creatives: _vm.allCreatives,
          "is-copy-available": _vm.isSelectedPublisher,
        },
        on: { delete: _vm.onDeleteCreative, edit: _vm.onEditCreative },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }