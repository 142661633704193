var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collapsible-container" },
    [
      _vm._t(
        "header",
        function () {
          return [
            _c(
              "header",
              {
                staticClass: "cursor-pointer flex collapsible-container-header",
                class: { open: _vm.isOpen },
                on: {
                  click: function ($event) {
                    return _vm.emit()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-1 collapsible-container-title" },
                  [_vm._t("title", null, { isOpen: _vm.isOpen })],
                  2
                ),
                _c("sun-arrow-down-svg", {
                  class: { "rotate-180": _vm.isOpen },
                }),
              ],
              1
            ),
          ]
        },
        { isOpen: _vm.isOpen }
      ),
      _c("transition", [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "collapsible-container-body" },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }