import { render, staticRenderFns } from "./Targeting.vue?vue&type=template&id=b702b220&"
import script from "./Targeting.vue?vue&type=script&lang=js&"
export * from "./Targeting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b702b220')) {
      api.createRecord('b702b220', component.options)
    } else {
      api.reload('b702b220', component.options)
    }
    module.hot.accept("./Targeting.vue?vue&type=template&id=b702b220&", function () {
      api.rerender('b702b220', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/modules/ecommerce/offer/form/targeting/Targeting.vue"
export default component.exports