var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            !_vm.isPublisherUser
              ? _c(
                  "router-link",
                  { attrs: { to: _vm.newOfferRoute() } },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass:
                          "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                        attrs: { variant: "pill", color: "gray" },
                      },
                      [_vm._v(" + New offer ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("sun-filter-layout", {
              attrs: {
                id: "filter-box",
                "filters-added": _vm.filterFind,
                "filters-available": _vm.availableFilters,
                "has-error": _vm.anyError,
                "show-reset": "",
              },
              on: {
                change: _vm.filtersSetFiltersFind,
                close: _vm.filtersResetErrors,
                remove: _vm.filtersResetErrors,
                reset: _vm.resetFilters,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "quickFilters",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "min-w-max" },
                          [
                            _c("sun-search-input", {
                              attrs: {
                                "class-input": "text-sm",
                                value: _vm.filterQuick[_vm.FILTER_SEARCH],
                              },
                              on: {
                                search: function ($event) {
                                  return _vm.onQuickFiltersSearch(
                                    $event,
                                    _vm.FILTER_SEARCH
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: `filter.name`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.countryIso`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("sun-select", {
                          staticClass: "w-24",
                          attrs: {
                            "close-on-select": "",
                            options: _vm.countries,
                            "track-by": filter.trackBy,
                            label: "name",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.status`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("sun-select", {
                          staticClass: "w-24",
                          attrs: {
                            options: filter.options,
                            "close-on-select": "",
                            "disable-selected-options": "",
                            "track-by": filter.trackBy,
                            label: "name",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.advertiser.id`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("advertiser-filter", {
                          attrs: {
                            "client-id": _vm.activeClient.id,
                            service: _vm.getAdvertisers,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.priceType`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("sun-select", {
                          staticClass: "w-24",
                          attrs: {
                            options: filter.options,
                            "track-by": filter.trackBy,
                            label: "name",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.createdAt`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-date-picker", {
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilterDate(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilterDate(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                        _c("sun-error-text", {
                          attrs: {
                            "show-error": !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `pill.text.createdAt`,
                    fn: function ({ filter }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("applyDateFormatter")(
                                _vm._f("stringToDate")(filter.value)
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-data-table", {
              staticClass: "w-full",
              attrs: {
                headers: _vm.headers,
                content: _vm.items,
                hoverable: "",
                striped: "",
                loading: _vm.isLoading,
              },
              on: { sort: _vm.onSortTable },
              scopedSlots: _vm._u(
                [
                  {
                    key: `col.name`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { class: columnClass, attrs: { title: item.name } },
                          [
                            _c("asterix-column-router-link", {
                              attrs: {
                                item: item,
                                route: _vm.offerRoute,
                                "param-value": "id",
                                "param-name": "offerId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.start`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            item.start
                              ? _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.start },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.end`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            item.end
                              ? _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.end },
                                })
                              : _c("span", [_vm._v(" N/A ")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.currencyId`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.currencyFormatted(item.currencies)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.countryName`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            _c("country-display", {
                              attrs: {
                                "show-name": "",
                                iso: item.country.iso3,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.status`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            _c("status-pill", {
                              attrs: {
                                color: _vm.statusColor(item.status),
                                status: item.status,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.advertiser`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            item.advertiser
                              ? _c("advertiser-link", {
                                  attrs: { advertiser: item.advertiser },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.createdAt`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            item.createdAt
                              ? _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.createdAt },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.grossPriceValue`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          item.priceValueType === _vm.costValueTypes.PRICE
                            ? _c("span", { staticClass: "flex flex-col" }, [
                                _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-600",
                                    },
                                    [_vm._v("Gross:")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.grossPriceValue || 0,
                                          item.currencies[0].id
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-600",
                                    },
                                    [_vm._v("Net:")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.netPriceValue || 0,
                                          item.currencies[0].id
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : item.priceValueType ===
                              _vm.costValueTypes.PERCENTAGE
                            ? _c("span", { staticClass: "flex flex-col" }, [
                                _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-600",
                                    },
                                    [_vm._v("Gross:")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("percentage")(
                                          item.grossPriceValue
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-600",
                                    },
                                    [_vm._v("Net:")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("percentage")(item.netPriceValue)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.actions`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { class: columnClass },
                          [
                            _c("table-action-menu", {
                              attrs: {
                                actions: _vm.actions,
                                item: item,
                                items: _vm.items,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onActionClick($event, item)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("asterix-no-data", { staticClass: "bg-white mt-8" }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-pagination-page", {
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
                "options-per-page": _vm.availablePageSizes,
              },
              on: {
                changePage: function ($event) {
                  return _vm.goToPage({ page: $event })
                },
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }