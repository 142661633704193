<template>
  <creative-link-item
    :creative="creative"
    :show-actions="showActions"
    :is-selected-publisher="isSelectedPublisher"
    @delete="$emit('delete', $event)"
    @edit="$emit('edit', $event)"
    @copy="$emit('copy', $event)"
  ></creative-link-item>
</template>

<script>
import CreativeLinkItem from '@/components/molecules/modules/ecommerce/offer/CreativeLinkItem';

export default {
  name: 'CreativeTextlinkItem',
  components: {
    CreativeLinkItem,
  },
  props: {
    /** @type CreativeTextlink */
    creative: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isSelectedPublisher: {
      type: Boolean,
      defalut: () => false,
    },
  },
};
</script>
