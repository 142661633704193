<template>
  <div>
    <asterix-form-modal
      v-if="showFormModal"
      :title="creativeFormTitle"
      closable
      @submit="onFormSubmit"
      @cancel="closeModal"
    >
      <template #content>
        <form-row>
          <sun-label-group class="w-full" text="Title">
            <asterix-input
              id="name"
              v-model="creative.name"
              type="text"
              name="name"
              validate-on-blur
              :minlength="3"
              :maxlength="50"
              text-error="the field is required."
              placeholder="My amazing creative"
              required="required"
            />
          </sun-label-group>
        </form-row>

        <form-row>
          <url-creator v-if="creative" v-model="creative.targetUrl" class="w-full" label="Target URL" required />
        </form-row>
      </template>
      <template #footer>
        <creative-modal-footer :loading="modalLoading" @cancel="closeModal" />
      </template>
    </asterix-form-modal>

    <form-row v-if="allCreatives.length">
      <sun-label-group class="w-full" text="Select publisher to get its code">
        <publisher-selector v-model="selectedPublisher" :publishers="publishers"></publisher-selector>
      </sun-label-group>
    </form-row>

    <div class="flex w-full mt-8 flex-col">
      <sun-label-group text="Add UTMs" class="w-full">
        <sun-text-area :value="utms.deeplink" resize="resize-y" @change="utmsChanges"></sun-text-area>
        <sun-button
          class="text-xs w-10 ml-2 h-6 self-end"
          :loading="utmButtonLoading"
          @click="updateUtms(offer.id, 'deeplink')"
        >
          Add
        </sun-button>
      </sun-label-group>
      <sun-error-text :show-error="!!utmError" :text-error="utmError"></sun-error-text>
    </div>

    <div class="text-right mt-8">
      <sun-button variant="pill" class="custom-p-1 text-xs" @click="showFormModal = true">+ new creative</sun-button>
    </div>

    <creative-list-deeplink
      :key="allCreatives.length"
      class="mt-2"
      show-actions
      :offer="offer"
      :is-loading="isLoadingLinkTracker || isLoadingUpdateOffer"
      :creatives="allCreatives"
      :is-copy-available="isSelectedPublisher"
      @delete="onDeleteCreative"
      @edit="onEditCreative"
    />
  </div>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import UrlCreator from '@/components/molecules/shared/UrlCreator/UrlCreator';
import CreativeDeeplink from '@/model/modules/ecommerce/offer/creative/creativeDeeplink/CreativeDeeplink';
import PublisherSelector from '@/components/organisms/modules/ecommerce/PublisherSelector';
import { creatives } from '@/services/modules/ecommerce/offer';
import { Toast } from '@/model/shared/Toast';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import CreativeListDeeplink from '@/components/organisms/modules/ecommerce/offer/creatives/CreativeListDeeplink';

export default {
  name: 'CreativeDeeplink',
  components: {
    CreativeListDeeplink,
    UrlCreator,
    FormRow,
    AsterixInput,
    PublisherSelector,
    CreativeModalFooter: () => import('@/components/molecules/shared/AsterixFooterModal'),
    AsterixFormModal,
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    defaultPublisher: {
      type: Object,
      default: () => null,
    },
    deepLinkTracker: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      allCreatives: null,
      modalLoading: false,
    };
  },
  watch: {
    offer: {
      deep: true,
      handler() {
        this.loadCreatives();
      },
    },
    deepLinkTracker: {
      deep: true,
      handler() {
        this.loadDeepLinkTracker(this.deepLinkTracker);
      },
    },
  },
  created() {
    this.creative = new CreativeDeeplink();
    this.utms.deeplink = this.offer.utms.deeplink;
    this.selectedPublisher = this.defaultPublisher;
    this.loadCreatives();
  },
  methods: {
    loadCreatives() {
      this.allCreatives = this.offer.creatives[CREATIVE_TYPES.DEEPLINK.id] || [];
      this.loadDeepLinkTracker(this.deepLinkTracker);
      this.isLoadingUpdateOffer = false;
    },
    async onDeleteCreative(creative) {
      try {
        this.createDefaultLoadingToast('deleted');
        const removeDeeplink = creatives.deeplink.remove;
        await removeDeeplink(this.offer?.id, creative?.id);
        this.isLoadingUpdateOffer = true;
        await this.debounceUpdate();
        this.setSuccessLoadingToast();
      } catch {
        this.setErrorLoadingToast();
      }
    },
    async onFormSubmit({ valid }) {
      if (valid) {
        this.modalLoading = true;
        if (this.isEditing) {
          const updateDeeplink = creatives.deeplink.update;
          await updateDeeplink(this.offer?.id, this.creative);

          this.updateLocalCreative();

          this.createToast(Toast.success('Creative updated', `Creative updated successfully!`));
        } else {
          const createDeeplink = creatives.deeplink.create;
          const { data: id } = await createDeeplink(this.offer?.id, this.creative);

          if (id) {
            this.creative.id = id;
            this.createToast(Toast.success('Creative created', `Creative created successfully!`));
          }
        }
        this.modalLoading = false;
        this.isLoadingUpdateOffer = true;
        this.closeModal();
        await this.debounceUpdate();
        await this.callDeepLinkTracker();
      }
    },
    utmsChanges(event) {
      this.utms.deeplink = event.value;
    },
    closeModal() {
      this.showFormModal = false;
      this.creative = new CreativeDeeplink();
    },
    updateLocalCreative() {
      const updateCrea = this.allCreatives.find(crea => crea.id === this.creative.id);
      updateCrea.name = this.creative.name;
      updateCrea.targetUrl = this.creative.targetUrl;
    },
  },
};
</script>

<style scoped>
::v-deep .url-creator-add,
::v-deep .url-creator-params {
  @apply px-4;
}
::v-deep .asterix-modal > div.bg-white.overflow-hidden {
  min-width: 66vw;
}
::v-deep textArea {
  min-height: 60px;
}
</style>
