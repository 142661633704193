var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("creative-link-item", {
    attrs: {
      creative: _vm.creative,
      "show-actions": _vm.showActions,
      "is-selected-publisher": _vm.isSelectedPublisher,
    },
    on: {
      delete: function ($event) {
        return _vm.$emit("delete", $event)
      },
      edit: function ($event) {
        return _vm.$emit("edit", $event)
      },
      copy: function ($event) {
        return _vm.$emit("copy", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }