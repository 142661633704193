<template>
  <div>
    <card-form v-if="baseOffer">
      <template #title> Create your creative</template>
      <template #form>
        <sun-label-group text="Select a creative type">
          <sun-select
            :key="creativeTypeKey"
            v-model="creativeType"
            disable-selected-options
            required="required"
            label="name"
            track-by="id"
            :options="creativeTypes"
          ></sun-select>
        </sun-label-group>

        <creative-factory
          v-if="creativeType"
          :offer="baseOffer"
          class="mt-8"
          :type="creativeType"
          @change="updateOffer"
        ></creative-factory>
      </template>
    </card-form>
    <card-form-loading v-else :rows="2"></card-form-loading>
  </div>
</template>

<script>
import CardForm from '@/components/atoms/CardForm';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import CreativeFactory from '@/components/organisms/modules/ecommerce/offer/form/creatives/CreativeFactory';
import { deepClone } from '@/utils/deepClone';
import CardFormLoading from '@/components/atoms/CardFormLoading';

export default {
  name: 'Creatives',
  components: {
    CardForm,
    CreativeFactory,
    CardFormLoading,
  },
  props: {
    /** @type Offer */
    baseOffer: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      creativeType: null,
      creativeTypeKey: 0,
    };
  },
  computed: {
    creativeTypes() {
      return Object.values(deepClone(CREATIVE_TYPES)).map(type => {
        const count = this.creativeCount[type.id];

        if (count > 0) {
          const name = CREATIVE_TYPES[type.id].name;
          type.name = `${name} (${count})`;
        }

        return type;
      });
    },
    creativeCount() {
      const count = {};

      if (this.baseOffer?.creatives) {
        Object.entries(this.baseOffer.creatives).forEach(entry => {
          const key = entry[0];
          const creas = entry[1];
          count[key] = creas?.length || 0;
        });
      }

      return count;
    },
  },
  watch: {
    creativeTypes(newTypes) {
      if (!this.creativeType) {
        this.creativeType = newTypes[0];
      }
      this.creativeTypeKey++;
      this.creativeType.name = `${CREATIVE_TYPES[this.creativeType.id].name} (${
        this.creativeCount[this.creativeType.id]
      })`;
    },
  },
  created() {
    this.creativeType = this.creativeTypes[0];
  },
  methods: {
    updateOffer() {
      this.$emit('reload');
    },
  },
};
</script>

<style scoped></style>
