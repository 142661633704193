<template>
  <creative-item
    :creative="creative"
    :show-actions="showActions"
    :is-selected-publisher="isSelectedPublisher"
    @delete="$emit('delete', $event)"
    @edit="$emit('edit', $event)"
    @copy="$emit('copy', $event)"
  >
    <form-row>
      <sun-label-group class="w-full" text="Title">
        <asterix-input v-model="creative.name" type="text" disabled />
      </sun-label-group>
    </form-row>
    <form-row>
      <sun-label-group class="w-full mt-2" text="Deeplink">
        <asterix-input v-if="isSelectedPublisher" v-model="creative.directLink" type="text" disabled />
        <asterix-input v-else value="N/A" type="text" disabled error="Must select a publisher"></asterix-input>
      </sun-label-group>
    </form-row>
  </creative-item>
</template>

<script>
import AsterixInput from '@/components/atoms/AsterixInput';
import CreativeItem from '@/components/molecules/modules/ecommerce/offer/CreativeItem';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'CreativeDeeplinkItem',
  components: {
    AsterixInput,
    CreativeItem,
    FormRow,
  },
  props: {
    /** @type CreativeDeeplink */
    creative: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isSelectedPublisher: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
