<template>
  <div>
    <creative-feed-modal
      v-if="showFormModal"
      v-model="creative"
      :loading="isLoading"
      @submit="onFormSubmit"
      @cancel="closeModal"
    >
      <template #title>
        {{ creativeFormTitle }}
      </template>
    </creative-feed-modal>

    <div class="text-right">
      <sun-button variant="pill" class="custom-p-1 text-xs" @click="showFormModal = true">+ new creative</sun-button>
    </div>

    <form-row v-if="feedAmount">
      <sun-label-group class="w-full mt-8" text="Select publisher to get its code">
        <publisher-selector v-model="selectedPublisher" :publishers="publishers"></publisher-selector>
      </sun-label-group>
    </form-row>

    <creative-list-feed
      :key="feedAmount"
      class="mt-8"
      show-actions
      :offer="offer"
      :is-loading="isLoadingUpdateOffer"
      :creatives="allCreatives"
      :is-selected-publisher="isSelectedPublisher"
      @delete="onDeleteCreative"
      @edit="onEditCreative"
    />
    <!-- <creative-feed-url-modal
      v-if="showUrlModal"
      :creative="creativeLastCreated"
      @cancel="showUrlModal = false"
    ></creative-feed-url-modal> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormRow from '@/components/atoms/FormRow/FormRow';
import PublisherSelector from '@/components/organisms/modules/ecommerce/PublisherSelector';
import { creatives } from '@/services/modules/ecommerce/offer';
import { Toast } from '@/model/shared/Toast';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import CreativeFeed from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeed';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import { copyToClipboard } from '@/utils/copyToClipboard';
import CreativeListFeed from '@/components/organisms/modules/ecommerce/offer/creatives/CreativeListFeed';
import apiRequest from '@/utils/apiRequest';

export default {
  name: 'CreativeFeed',
  components: {
    FormRow,
    PublisherSelector,
    CreativeListFeed,
    CreativeFeedModal: () =>
      import('@/components/organisms/modules/ecommerce/offer/form/creatives/creativeTypes/CreativeFeedModal.vue'),
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    defaultPublisher: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      creative: new CreativeFeed(),
      created: [],
      removed: [],
      lastCreatedId: null,
      showFormModal: false,
      showUrlModal: false,
      CREATIVE_TYPES,
      isLoading: false,
      allCreatives: null,
    };
  },
  computed: {
    feedAmount() {
      return this.allCreatives?.length;
    },
    publishers() {
      return this.offer?.publishers.map(p => p.publisher);
    },
    isEditing() {
      return !!this.creative?.id;
    },
    creativeFormTitle() {
      return this.isEditing ? 'Edit creative' : 'New creative';
    },
    creativeLastCreated() {
      return this.allCreatives.find(crea => crea.id === this.lastCreatedId);
    },
  },
  watch: {
    offer: {
      deep: true,
      handler() {
        this.loadCreatives();
      },
    },
  },
  mounted() {
    this.selectedPublisher = this.defaultPublisher;
    this.allCreatives = this.offer.creatives[CREATIVE_TYPES.FEED.id] || [];
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),

    loadCreatives() {
      this.allCreatives = this.offer.creatives[CREATIVE_TYPES.FEED.id] || [];
      this.isLoadingUpdateOffer = false;
    },
    async onDeleteCreative(creative) {
      try {
        this.createDefaultLoadingToast('deleted');
        const removeFeed = creatives.feed.remove;
        await removeFeed(this.offer.id, creative?.id);
        this.removed.push(creative.id);
        this.isLoadingUpdateOffer = true;
        await this.debounceUpdate();
        this.setSuccessLoadingToast();
      } catch {
        this.setErrorLoadingToast();
      }
    },
    async onFormSubmit({ valid }) {
      if (valid) {
        this.isLoading = true;
        if (this.isEditing) {
          await this.updateFeed();
        } else {
          await this.createFeed();
        }
        this.isLoading = false;
        this.closeModal();
      } else {
        this.createToast(Toast.error('Error with File', 'File must be mapped'));
      }
    },
    async updateFeed() {
      this.createDefaultLoadingToast('updated');
      const updateFeed = creatives.feed.update;
      try {
        await updateFeed(this.offer.id, this.creative);
        this.isLoadingUpdateOffer = true;
        await this.debounceUpdate();
        this.setSuccessLoadingToast();
      } catch {
        this.setErrorLoadingToast();
      }
    },
    async createFeed() {
      this.createDefaultLoadingToast('created');
      const createFeed = creatives.feed.create;
      try {
        const creativeId = await createFeed(this.offer.id, this.creative);
        if (creativeId?.id) {
          this.creative.id = creativeId.id;
          this.created.push(this.creative.clone());
          this.isLoadingUpdateOffer = true;
          await this.debounceUpdate();
          this.setSuccessLoadingToast();
          this.lastCreatedId = creativeId.id;
          this.showUrlModal = true;
        }
      } catch {
        this.setErrorLoadingToast();
      }
    },
    onEditCreative(creative) {
      this.creative = creative.clone();
      this.showFormModal = true;
    },
    closeModal() {
      this.showFormModal = false;
      this.creative = new CreativeFeed();
    },
    async onCopy(creative) {
      if (!creative.publisher) return;
      this.setLoadingToast(creative);
      await apiRequest(async () => {
        const { code } = await creatives.feed.getPublisherTrackerLink(this.offer?.id, creative);
        copyToClipboard(code);
        this.setSuccessLoadingToast(false);
      }).catch(() => {
        this.setErrorLoadingToast();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .url-creator-add,
::v-deep .url-creator-params {
  @apply px-4;
}
::v-deep .asterix-modal > div.bg-white.overflow-hidden {
  min-width: 66vw;
}
</style>
