<template>
  <div>
    <offer-history :is-loading="isLoading || isTableLoading" :entity="tableHistoryContent" />
    <offer-comments :is-loading="isLoading" :offer="baseOffer" class="mt-8" @update="$emit('reload')" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OfferHistory from '@/components/organisms/modules/ecommerce/offer/OfferHistory.vue';
import OfferComments from '@/components/organisms/modules/ecommerce/offer/OfferComments.vue';
import { getOfferHistory } from '@/services/modules/ecommerce/offer';
import { CONFIG } from './config';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import apiRequest from '@/utils/apiRequest';

export default {
  name: 'History',
  components: {
    OfferHistory,
    OfferComments,
  },
  props: {
    baseOffer: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      isTableLoading: true,
      offerHistory: {},
      tableHistoryContent: {
        headers: CONFIG.headers,
        data: [],
      },
    };
  },
  async created() {
    await this.getHistory();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async getHistory() {
      await apiRequest(async () => {
        this.offerHistory = await getOfferHistory(this.$route.params.offerId);
        this.formatTableContent();
      }).catch(error => {
        this.createToast(Toast.error('Settings not updated', error.message));
      });
      this.isTableLoading = false;
    },

    formatTableContent() {
      const formatedData = [];
      this.offerHistory.data.forEach(event => {
        const eventType = event.eventName.split('.');
        formatedData.push({
          date: event.occurredOn.split(' ')[0],
          user: event.user.name,
          activity: eventType[eventType.length - 1],
          activity_detail: eventType[eventType.length - 1],
        });
      });
      this.tableHistoryContent.data = formatedData;
    },
  },
};
</script>
