var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "creative-item",
    {
      attrs: {
        creative: _vm.creative,
        "show-actions": _vm.showActions,
        "is-selected-publisher": _vm.isSelectedPublisher,
      },
      on: {
        delete: function ($event) {
          return _vm.$emit("delete", $event)
        },
        edit: function ($event) {
          return _vm.$emit("edit", $event)
        },
        copy: function ($event) {
          return _vm.$emit("copy", $event)
        },
      },
    },
    [
      _c(
        "form-row",
        [
          _c(
            "sun-label-group",
            { staticClass: "w-full", attrs: { text: "Title" } },
            [
              _c("asterix-input", {
                attrs: { type: "text", disabled: "" },
                model: {
                  value: _vm.creative.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.creative, "name", $$v)
                  },
                  expression: "creative.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form-row",
        [
          _c(
            "sun-label-group",
            { staticClass: "w-full mt-2", attrs: { text: "Target Url" } },
            [
              _c("asterix-input", {
                attrs: { type: "text", disabled: "" },
                model: {
                  value: _vm.creative.targetUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.creative, "targetUrl", $$v)
                  },
                  expression: "creative.targetUrl",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }