<template>
  <div>
    <line-loader v-if="isLoading" />
    <div v-else :class="classes">
      <p v-if="title" class="text-md font-bold mr-1 whitespace-no-wrap text-gray-600">{{ title }}</p>
      <slot name="content">
        <span class="whitespace-no-wrap truncate"><slot name="text"></slot></span>
      </slot>
    </div>
  </div>
</template>

<script>
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'DetailItem',
  components: {
    LineLoader,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: () => 'mt-4',
    },
  },
};
</script>
