<template>
  <asterix-section :status="sectionStatus">
    <template #header-right>
      <router-link v-if="!isPublisherUser" :to="newOfferRoute()">
        <sun-button variant="pill" class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800" color="gray">
          + New offer
        </sun-button>
      </router-link>
    </template>

    <template #content>
      <sun-filter-layout
        id="filter-box"
        :filters-added="filterFind"
        :filters-available="availableFilters"
        :has-error="anyError"
        show-reset
        @change="filtersSetFiltersFind"
        @close="filtersResetErrors"
        @remove="filtersResetErrors"
        @reset="resetFilters"
      >
        <template #quickFilters>
          <div class="min-w-max">
            <sun-search-input
              class-input="text-sm"
              :value="filterQuick[FILTER_SEARCH]"
              @search="onQuickFiltersSearch($event, FILTER_SEARCH)"
            />
          </div>
        </template>
        <!--Filters Slot :Start -->
        <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
        <template #[`filter.countryIso`]="{ filter, onSelect, value, isDuplicate }">
          <sun-select
            close-on-select
            :options="countries"
            :track-by="filter.trackBy"
            label="name"
            :value="filtersMakeFiltersForSelect(value)"
            class="w-24"
            add-hex-color="orange"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
          ></sun-select>
        </template>
        <template #[`filter.status`]="{ filter, onSelect, value, isDuplicate }">
          <sun-select
            :options="filter.options"
            close-on-select
            disable-selected-options
            :track-by="filter.trackBy"
            label="name"
            :value="filtersMakeFiltersForSelect(value)"
            class="w-24"
            add-hex-color="orange"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
        <template #[`filter.advertiser.id`]="{ filter, onSelect, value, isDuplicate }">
          <advertiser-filter
            :client-id="activeClient.id"
            :service="getAdvertisers"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
          />
        </template>
        <template #[`filter.priceType`]="{ filter, onSelect, value, isDuplicate }">
          <sun-select
            :options="filter.options"
            :track-by="filter.trackBy"
            label="name"
            :value="filtersMakeFiltersForSelect(value)"
            class="w-24"
            add-hex-color="orange"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
          ></sun-select>
        </template>

        <template #[`filter.createdAt`]="{ filter, onSelect, isDuplicate }">
          <sun-date-picker
            @change="filtersOnSelectFilterDate(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilterDate(filter, $event.value, onSelect)"
          />
          <sun-error-text
            :show-error="!!filtersGetFilterError(filter, isDuplicate)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
          />
        </template>
        <template #[`pill.text.createdAt`]="{ filter }">
          {{ filter.value | stringToDate | applyDateFormatter }}
        </template>
      </sun-filter-layout>

      <sun-data-table
        :headers="headers"
        :content="items"
        class="w-full"
        hoverable
        striped
        :loading="isLoading"
        @sort="onSortTable"
      >
        <template #[`col.name`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass" :title="item.name">
            <asterix-column-router-link :item="item" :route="offerRoute" param-value="id" param-name="offerId" />
          </sun-data-table-cell>
        </template>

        <template #[`col.start`]="{ item }">
          <sun-data-table-cell>
            <date-tooltip v-if="item.start" class="inline-flex" :date="item.start" />
          </sun-data-table-cell>
        </template>

        <template #[`col.end`]="{ item }">
          <sun-data-table-cell>
            <date-tooltip v-if="item.end" class="inline-flex" :date="item.end" />
            <span v-else> N/A </span>
          </sun-data-table-cell>
        </template>

        <template #[`col.currencyId`]="{ item }">
          <sun-data-table-cell>
            {{ currencyFormatted(item.currencies) }}
          </sun-data-table-cell>
        </template>

        <template #[`col.countryName`]="{ item }">
          <sun-data-table-cell>
            <country-display show-name :iso="item.country.iso3" />
          </sun-data-table-cell>
        </template>

        <template #[`col.status`]="{ item }">
          <sun-data-table-cell>
            <status-pill :color="statusColor(item.status)" :status="item.status" />
          </sun-data-table-cell>
        </template>

        <template #[`col.advertiser`]="{ item }">
          <sun-data-table-cell>
            <advertiser-link v-if="item.advertiser" :advertiser="item.advertiser" />
          </sun-data-table-cell>
        </template>

        <template #[`col.createdAt`]="{ item }">
          <sun-data-table-cell>
            <date-tooltip v-if="item.createdAt" class="inline-flex" :date="item.createdAt" />
          </sun-data-table-cell>
        </template>

        <template #[`col.grossPriceValue`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            <span v-if="item.priceValueType === costValueTypes.PRICE" class="flex flex-col">
              <span>
                <span class="font-semibold text-gray-600">Gross:</span>
                {{ item.grossPriceValue || 0 | currency(item.currencies[0].id) }}
              </span>
              <span>
                <span class="font-semibold text-gray-600">Net:</span>
                {{ item.netPriceValue || 0 | currency(item.currencies[0].id) }}
              </span>
            </span>
            <span v-else-if="item.priceValueType === costValueTypes.PERCENTAGE" class="flex flex-col">
              <span>
                <span class="font-semibold text-gray-600">Gross:</span>
                {{ item.grossPriceValue | percentage }}
              </span>
              <span>
                <span class="font-semibold text-gray-600">Net:</span>
                {{ item.netPriceValue | percentage }}
              </span>
            </span>
          </sun-data-table-cell>
        </template>

        <template #[`col.actions`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
          </sun-data-table-cell>
        </template>

        <template #empty>
          <asterix-no-data class="bg-white mt-8" />
        </template>
      </sun-data-table>

      <sun-pagination-page
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        :options-per-page="availablePageSizes"
        @changePage="goToPage({ page: $event })"
        @changePerPage="changeItemsPerPage"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import AsterixSection from '@/components/templates/AsterixSection';
import { COUNTRY_NS, COUNTRIES_KEY, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import { SAVE_FILTERS } from '@/store/modules/filters/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { USER } from '@/store/modules/auth/keys';
import { ROLES } from '@/model/shared/roles';
import { getOffers } from '@/services/modules/ecommerce/offer';
import AdvertiserLink from '@/components/atoms/AdvertiserLink';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { applyDateFormatters, dateToYYYYMMDD, stringToDate } from '@/filters/dateFilters';
import { spanishDateFormatter } from '@/model/shared/DateFormatter';
import { create as newOffer } from '@/router/private/modules/ecommerce/demand/offer/create';
import { edit as editOffer } from '@/router/private/modules/ecommerce/demand/offer/edit';
import { duplicate as duplicateOffer } from '@/router/private/modules/ecommerce/demand/offer/duplicate';
import { history as offerDetail } from '@/router/private/modules/ecommerce/demand/offer/showDetails/history';
import { stats } from '@/router/private/modules/ecommerce/analytics/stats';
import { getAdvertisers } from '@/services/modules/ecommerce/advertiser';
import CONFIG from './config.js';
import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig.js';
import COST_VALUE_TYPES from '@/model/modules/ecommerce/offer/costValueTypes';
import { getUserById } from '@/services/modules/Core/user';
import { FILTER_SEARCH } from '@/model/shared/QuickFilterSearchName';
import AsterixColumnRouterLink from '@/components/atoms/AsterixColumnRouterLink';

export default {
  name: 'OfferList',
  components: {
    AsterixSection,
    AdvertiserLink,
    AsterixColumnRouterLink,
    AdvertiserFilter: () => import('@/components/molecules/shared/filters/AdvertiserFilter'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    CountryDisplay: () => import('@/components/molecules/shared/CountryDisplay.vue'),
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
  },
  filters: {
    stringToDate,
    applyDateFormatter: date => applyDateFormatters(date, spanishDateFormatter),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { [FILTER_SEARCH]: undefined },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    items: [],
    currentApi: new EcommerceApi(),
    resource: ADVERTISER_RESOURCE,
    getAdvertisers,
    costValueTypes: COST_VALUE_TYPES,
    userInfo: null,
    FILTER_SEARCH,
    availablePageSizes: [20, 50, 100],
  }),
  computed: {
    ...mapState(COUNTRY_NS, {
      countries: COUNTRIES_KEY,
    }),
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
    }),
    isPublisherUser() {
      return this.userRole === ROLES.PUBLISHER.id;
    },
    userRole() {
      return this.user.contextRoles.find(rol => rol.context === this.contextActive.id).role;
    },
    actions() {
      return [{ name: this.isPublisherUser ? 'Show' : 'Edit' }, { name: 'Duplicate' }, { name: 'Stats' }];
    },
    offerRoute() {
      if (this.isPublisherUser) {
        return offerDetail;
      }
      return editOffer;
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
  },
  async mounted() {
    if (this.isPublisherUser) {
      const user = await getUserById(this.user.id);
      this.userInfo = user.data;
    }
    await this.getCountriesRequest();
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({
      getCountriesRequest: GET_COUNTRIES_REQUEST,
      SAVE_FILTERS,
    }),
    onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.editOffer(item.id, item.advertiser.id);
          break;
        case 'Show':
          this.showOffer(item.id);
          break;
        case 'Stats':
          this.statsOffer(item.id);
          break;
        case 'Duplicate':
          this.duplicateOffer(item.id);
          break;
      }
    },
    // TODO filters
    currencyFormatted(currencies) {
      const currenciesFormatted = currencies.map(currency => {
        return `${currency.id}(${currency.symbol})`;
      });
      return currenciesFormatted.join(', ');
    },
    editOffer(id) {
      const route = this.newOfferRoute(id);
      this.$router.push(route);
    },
    showOffer(id) {
      this.$router.push({ name: offerDetail.name, params: { offerId: id } });
    },
    statsOffer(id) {
      this[SAVE_FILTERS]([
        { filterConfig: { key: FILTERS_CONFIG.OFFER_ID.key }, name: FILTERS_CONFIG.OFFER_ID.name, value: id },
      ]);

      this.$router.push({ name: stats.name, params: { offerId: id } });
    },
    duplicateOffer(id) {
      this.$router.push({ name: duplicateOffer.name, params: { offerId: id } });
    },
    newOfferRoute(id = undefined) {
      let route = { name: newOffer.name };

      if (id) {
        route = { name: editOffer.name, params: { offerId: id } };
      }
      return route;
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getOffers);
      this.items = data;
      if (this.isPublisherUser) {
        this.items.forEach(offer => {
          this.publisherPrice(offer);
        });
      }
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    filtersOnSelectFilterDate(filter, value, onSelect) {
      const date = dateToYYYYMMDD(stringToDate(value.startDate));
      this.filtersOnSelectFilter(filter, date, onSelect);
    },
    publisherPrice(offer) {
      const coincidence = [];
      this.userInfo.publishers.forEach(publisher => {
        const pub = offer.publishers.find(ofPublisher => ofPublisher.publisherId === publisher.id);
        if (pub) {
          coincidence.push(pub);
        }
      });
      if (coincidence.length === 1) {
        offer.costValue = coincidence[0].publisherPrice;
      } else {
        offer.costValue = null;
      }
    },
    statusColor(status) {
      switch (status.toUpperCase()) {
        case 'ACTIVE':
          return 'green';
        case 'PAUSED':
          return 'yellow';
        case 'FINISHED':
          return 'pink';
        default:
          return 'gray';
      }
    },
  },
};
</script>
