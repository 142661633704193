var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", { attrs: { d: "M0 0h24v24H0V0z", fill: "none" } }),
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }