/**
 * @param data {Object}
 * @param fileName {String}
 * @param mimeType {String}
 */
const fileDownload = (data, fileName, mimeType = 'application/octet-stream') => {
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return url;
  }
  return null;
};
export default fileDownload;
