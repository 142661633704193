<template>
  <div class="flex flex-col w-full">
    <prism-editor
      v-model="code"
      class="code-editor"
      :highlight="highlighter"
      line-numbers
      :readonly="readonly"
      @blur="onBlur($event)"
      @input="onChange($event)"
      @focus="onFocus()"
    />
    <sun-error-text v-show="internalError" show-error :text-error="textError"></sun-error-text>
  </div>
</template>

<script>
import Prism from 'prismjs';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';

export default {
  components: {
    PrismEditor,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    internalError: {
      type: Boolean,
      default: false,
    },
    textError: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    code: '',
    lineNumbers: true,
    focus: false,
  }),
  watch: {
    value(newCode) {
      this.code = newCode;
    },
    code(newCode) {
      this.$emit('input', newCode);
    },
  },
  created() {
    this.code = this.value;
  },
  methods: {
    highlighter(code) {
      return highlight(code, Prism.languages.markup, 'markup');
    },
    onFocus() {
      this.focus = true;
      this.$emit('focus');
    },
    onChange(event) {
      this.$emit('change', event);
    },
    onBlur(event) {
      this.focus = false;
      this.$emit('blur', event.srcElement.value);
    },
  },
};
</script>

<style scoped lang="scss">
.code-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

::v-deep .prism-editor__container textarea:focus-visible,
::v-deep .prism-editor__textarea:focus-visible,
::v-deep .prism-editor-wrapper .prism-editor__textarea--empty:focus-visible {
  @apply outline-none;
}

::v-deep input {
  @apply hidden;
}
</style>
