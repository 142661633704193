var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showName
        ? _c(
            "sun-popover",
            {
              staticClass: "inline-flex",
              attrs: { position: "right", "text-info": _vm.countryName },
            },
            [
              _c("country-flag", {
                staticClass: "align-middle",
                attrs: { country: _vm.iso, size: "normal" },
              }),
              _c("span", { staticClass: "ml-2 align-middle" }, [
                _vm._v(_vm._s(_vm.countryName)),
              ]),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("country-flag", {
                staticClass: "align-middle",
                attrs: { country: _vm.iso, size: "normal" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }