var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v("Basic Info")]),
      _c("template", { slot: "form" }, [
        _c(
          "div",
          { staticClass: "w-full flex flex-wrap text-left" },
          [
            _c(
              "div",
              { staticClass: "w-full sm:w-1/2 px-10" },
              [
                _c("p", { staticClass: "subtitle" }, [_vm._v("Basic Info")]),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Offer status" },
                  scopedSlots: _vm._u(
                    [
                      !_vm.isLoading
                        ? {
                            key: "text",
                            fn: function () {
                              return [
                                _vm._v(_vm._s(_vm.offer.status || _vm.noData)),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                }),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Advertiser" },
                  scopedSlots: _vm._u(
                    [
                      !_vm.isLoading
                        ? {
                            key: "text",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.offer.advertiser.name))]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                }),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Tags" },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(_vm._s(_vm.offerTags || _vm.noData))]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Time line" },
                  scopedSlots: _vm._u(
                    [
                      !_vm.isLoading
                        ? {
                            key: "text",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("applyDateFormatter")(
                                        _vm.offer.start
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("applyDateFormatter")(
                                        _vm.offer.end
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                }),
                _c("p", { staticClass: "subtitle" }, [_vm._v("Attribution")]),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Postclick" },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(_vm._s(_vm.postClickActive))]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm.offer.offerAttribution.postclick_active
                  ? _c("detail-item", {
                      attrs: {
                        "is-loading": _vm.isLoading,
                        title: "Postclick value",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(_vm.postClickValue || _vm.noData)
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2348965761
                      ),
                    })
                  : _vm._e(),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Postview" },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(_vm._s(_vm.postViewActive))]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm.offer.offerAttribution.postview_active
                  ? _c("detail-item", {
                      attrs: {
                        "is-loading": _vm.isLoading,
                        title: "Postview value",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _vm._v(_vm._s(_vm.postViewValue || _vm.noData)),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2825911298
                      ),
                    })
                  : _vm._e(),
                _c("p", { staticClass: "subtitle" }, [_vm._v("Direct link")]),
                _c("asterix-input-and-copy", {
                  staticClass: "w-full",
                  attrs: {
                    "is-loading": _vm.isLoading,
                    title: "Offer pixel code",
                    value: _vm.publisherDetails.directLink,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full sm:w-1/2 px-10" },
              [
                _c("p", { staticClass: "subtitle" }, [
                  _vm._v("Payment method"),
                ]),
                _c("detail-item", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    title: "Payment method",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(_vm._s(_vm.offer.priceType))]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("detail-item", {
                  attrs: { "is-loading": _vm.isLoading, title: "Price" },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(_vm._s(_vm.priceToShow))]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("p", { staticClass: "subtitle" }, [_vm._v("Capping")]),
                _c("detail-item", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    title: "Total capping",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [
                          _vm._v(_vm._s(_vm.offer.cap.total || _vm.noData)),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("p", { staticClass: "subtitle" }, [_vm._v("Target")]),
                _c("detail-item", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    title: "Target countries",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [
                          _c("country-display", {
                            staticClass: "mr-5",
                            attrs: {
                              "show-name": "",
                              iso: _vm.offer.country.iso3,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm.entityPixelToShow
              ? _c("copy-pixel-modal", {
                  attrs: { value: _vm.entityPixelToShow },
                  on: {
                    cancel: function ($event) {
                      _vm.entityPixelToShow = null
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }