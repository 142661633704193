const INCLUDE_NAMES = {
  browserFilter: 'Browser',
  deviceFilter: 'Device',
  operatingSystemFilter: 'OS',
  languageFilter: 'Language',
  regionFilter: 'Region',
  countryFilter: 'Country',
};

export default INCLUDE_NAMES;
