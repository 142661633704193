var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "creative w-full" },
    [
      _c(
        "collapsible-container",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex w-full pr-2 items-center" },
                    [
                      _c("h1", {}, [_vm._v(_vm._s(_vm.creative.name))]),
                      _vm.showStatusPill
                        ? _c("status-pill", {
                            staticClass: "ml-4",
                            attrs: {
                              color: "red",
                              status: _vm.creative.status,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "flex flex-1 justify-end" },
                        [
                          _vm.showActions
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "hover:bg-gray-400 action-button mr-1",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.$emit("edit", _vm.creative)
                                    },
                                  },
                                },
                                [_c("edit-svg", { staticClass: "w-4 h-4" })],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "sun-popover",
                            {
                              staticClass: "mr-1 static flex-none",
                              attrs: { "text-info": _vm.clipboardHint },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "hover:bg-gray-400 action-button align-bottom",
                                  class: { disabled: _vm.isDisabled },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.emitCopy.apply(null, arguments)
                                    },
                                  },
                                },
                                [_c("copy-svg", { staticClass: "w-4 h-4" })],
                                1
                              ),
                            ]
                          ),
                          _vm.showActions
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "hover:bg-red-400 text-white action-button",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.showDelete.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("rubbis-bin-svg", {
                                    staticClass: "w-4 h-4",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "p-4 border-t border-gray-300" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _c("delete-modal", {
        attrs: { open: _vm.showDeleteModal },
        on: {
          confirm: _vm.confirmDeleteModal,
          cancel: function ($event) {
            _vm.showDeleteModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.deleteModalDescription))]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }