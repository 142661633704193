<template>
  <div>
    <creative-preview v-if="creatives.length" class="w-full">
      <div v-for="(size, sizeIndex) in previewFilesBySize" :key="sizeIndex" :class="{ 'mt-8': sizeIndex !== 0 }">
        <header class="text-left">
          <h1>{{ size.size }}</h1>
        </header>
        <div v-if="!isLoading" class="flex flex-wrap">
          <creative-preview-banner-image
            v-for="img in size.items"
            :key="img.url"
            :creative="img"
            alt="creative image"
            :show-actions="showActions"
            :is-selected-publisher="isCopyAvailable"
            @delete="$emit('delete', img)"
            @copy="onCopy"
            @edit="$emit('edit', img)"
          />
        </div>
        <div v-else>
          <line-loader v-for="img in size.items" :key="img.url"></line-loader>
        </div>
      </div>
    </creative-preview>
    <asterix-no-data
      v-else
      subtitle="Create one using the button above"
      class="border border-gray-300 rounded-md shadow-md text-center mt-8"
    >
      <template #title> There are not <span class="italic">banner</span> creatives yet </template>
    </asterix-no-data>
  </div>
</template>

<script>
import CreativePreview from '@/components/organisms/shared/creatives/CreativePreview';
import CreativePreviewBannerImage from '@/components/molecules/modules/ecommerce/offer/CreativePreviewBannerImage';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import { getPublisherTrackerLink } from '@/services/modules/ecommerce/offer/creatives/banner/getPublisherTrackerLink';
import { copyToClipboard } from '@/utils/copyToClipboard';
import CreativeBanner from '@/model/modules/ecommerce/offer/creative/creativeBanner/CreativeBanner';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';
import apiRequest from '@/utils/apiRequest';
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'CreativeListBanner',
  components: {
    CreativePreview,
    CreativePreviewBannerImage,
    AsterixNoData,
    LineLoader,
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type: Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    creatives: {
      type: Array,
      default: () => null,
    },
    isCopyAvailable: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    previewFilesBySize() {
      const measures = new Set(this.creatives.map(file => `${file.width}x${file.height}`));
      const sizes = [...measures];
      return sizes
        .map(size => {
          let [width, height] = size.split('x');
          width = parseInt(width);
          height = parseInt(height);
          const items = this.creatives.filter(file => file.width === width && file.height === height);

          return { size, items };
        })
        .filter(size => size.items?.length);
    },
  },
  watch: {
    creatives() {
      this.renderPreview();
    },
  },
  mounted() {
    this.renderPreview();
  },
  methods: {
    renderPreview() {
      this.creatives.forEach(crea => {
        const img = new Image();

        img.onload = () => {
          const file = this.creatives.find(f => f.id === crea.id);
          if (file) {
            this.$set(file, 'width', img.naturalWidth || img.width);
            this.$set(file, 'height', img.naturalHeight || img.height);
          }
        };
        img.src = crea.url;
      });
    },
    async onCopy(creative) {
      if (!creative.publisher || !this.offer.id) return;
      const bannerCreative = new CreativeBanner(
        creative.id,
        creative.publisher,
        creative.name,
        creative.path,
        creative.tagsTemplate,
        creative.url,
        creative.width,
        creative.height
      );
      this.setLoadingToast(bannerCreative);
      await apiRequest(async () => {
        const { data } = await getPublisherTrackerLink(this.offer.id, bannerCreative);
        copyToClipboard(data);
        this.setSuccessLoadingToast();
      }).catch(() => {
        this.setErrorLoadingToast();
      });
    },
  },
};
</script>
