var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.creativeComponent, {
    tag: "component",
    attrs: {
      offer: _vm.offer,
      "default-publisher": _vm.defaultPublisher,
      "deep-link-tracker": _vm.deepLinkTracker,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
      updatePublisher: function ($event) {
        _vm.defaultPublisher = $event
      },
      deepLinkTracker: function ($event) {
        _vm.deepLinkTracker = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }