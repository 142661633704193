var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("offer-history", {
        attrs: {
          "is-loading": _vm.isLoading || _vm.isTableLoading,
          entity: _vm.tableHistoryContent,
        },
      }),
      _c("offer-comments", {
        staticClass: "mt-8",
        attrs: { "is-loading": _vm.isLoading, offer: _vm.baseOffer },
        on: {
          update: function ($event) {
            return _vm.$emit("reload")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }