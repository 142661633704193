<template>
  <div>
    <sun-popover v-if="showName" class="inline-flex" position="right" :text-info="countryName">
      <country-flag class="align-middle" :country="iso" size="normal" />
      <span class="ml-2 align-middle">{{ countryName }}</span>
    </sun-popover>
    <div v-else>
      <country-flag class="align-middle" :country="iso" size="normal" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CountryFlag from 'vue-country-flag';
import { COUNTRY_NS, COUNTRIES_KEY, COUNTRY_BY_ID, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';

export default {
  name: 'CountryDisplay',
  components: {
    CountryFlag,
  },
  props: {
    showName: {
      type: Boolean,
      default: false,
    },
    iso: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(COUNTRY_NS, {
      countries: COUNTRIES_KEY,
    }),
    ...mapGetters({ getCountryById: COUNTRY_BY_ID }),
    countryName() {
      if (!this.showName) return '';
      if (!this.countries.length) return '';

      const country = this.getCountryById(this.iso);
      return country.name;
    },
  },
  created() {
    if (this.showName) this.getCountries();
  },
  methods: {
    ...mapActions({ getCountries: GET_COUNTRIES_REQUEST }),
  },
};
</script>
