<template>
  <div class="collapsible-container">
    <slot name="header" :is-open="isOpen">
      <header class="cursor-pointer flex collapsible-container-header" :class="{ open: isOpen }" @click="emit()">
        <div class="flex-1 collapsible-container-title">
          <slot name="title" :is-open="isOpen"> </slot>
        </div>
        <sun-arrow-down-svg :class="{ 'rotate-180': isOpen }"></sun-arrow-down-svg>
      </header>
    </slot>

    <transition>
      <div v-if="isOpen" class="collapsible-container-body">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'CollapsibleContainer',
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    value(newValue) {
      this.isOpen = newValue;
    },
  },
  created() {
    this.isOpen = this.value;
  },
  methods: {
    emit() {
      this.isOpen = !this.isOpen;
      this.$emit('input', this.isOpen);
    },
  },
};
</script>
