<template>
  <creative-item
    v-if="creative"
    :creative="creative"
    :show-actions="showActions"
    :is-selected-publisher="isSelectedPublisher"
    @delete="$emit('delete', $event)"
    @edit="$emit('edit', $event)"
    @copy="$emit('copy', $event)"
  >
    <form-row>
      <sun-label-group v-if="creative.size" class="w-full mb-1" text="Size">
        <asterix-input v-model="creative.size.name" type="text" disabled />
      </sun-label-group>
    </form-row>
    <form-row>
      <sun-label-group class="w-full mb-1" text="Title">
        <asterix-input v-model="creative.name" type="text" disabled />
      </sun-label-group>
    </form-row>
    <form-row>
      <sun-label-group class="w-full mb-1" text="HTML code">
        <code-editor-preview :is-publisher-role="isPublisherRole" :value="creative.code" readonly />
      </sun-label-group>
    </form-row>
    <form-row v-if="!isPublisherRole">
      <sun-label-group class="w-full" text="Keep HTML links">
        <sun-toggle v-model="creative.keepHtmlLinks" disabled />
      </sun-label-group>
    </form-row>
    <form-row v-if="!isPublisherRole">
      <sun-label-group class="w-full mb-1" text="External code">
        <code-editor :value="creative.externalCode" readonly />
      </sun-label-group>
    </form-row>
  </creative-item>
</template>

<script>
import AsterixInput from '@/components/atoms/AsterixInput';
import CreativeItem from '@/components/molecules/modules/ecommerce/offer/CreativeItem';
import CodeEditorPreview from '@/components/organisms/shared/CodeEditorPreview';
import CodeEditor from '@/components/organisms/shared/CodeEditor';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'CreativeHtmlItem',
  components: {
    CreativeItem,
    FormRow,
    CodeEditorPreview,
    CodeEditor,
    AsterixInput,
  },
  props: {
    /** @type CreativeHtml */
    creative: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isSelectedPublisher: {
      type: Boolean,
      default: () => false,
    },
    isPublisherRole: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      toggleValue: true,
    };
  },
};
</script>
