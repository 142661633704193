import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig.js';
import Column from '@/model/shared/Column';

export default Object.freeze({
  headers: [
    new Column('NAME', 'name').asSort().setClass('font-bold truncate'),
    new Column('START DATE', 'start').asSort(true),
    new Column('END DATE', 'end').asSort(true),
    new Column('CURRENCY', 'currencyId').asSort(),
    new Column('COUNTRY', 'countryName').asSort(),
    new Column('ADVERTISER', 'advertiser'),
    new Column('STATUS', 'status').asSort(),
    new Column('CREATED AT', 'createdAt').asSort(true),
    new Column('PAYMENT', 'priceType').asSort(),
    new Column('PAYOUT', 'grossPriceValue').asSort(true).toRight(),
    new Column('TOTAL LEADS', 'totalLeads').toRight(),
    new Column('ACTIONS', 'actions').setClass('w-4'),
  ],
  filters: [
    FILTERS_CONFIG.OFFER_NAME,
    FILTERS_CONFIG.OFFER_STATUS,
    FILTERS_CONFIG.PAYMENT,
    FILTERS_CONFIG.ADVERTISER_ID,
    FILTERS_CONFIG.COUNTRY_ISO,
    FILTERS_CONFIG.CREATED_AT,
  ],
});
