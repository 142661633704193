<template>
  <div>
    <creative-list v-if="!isLoading" class="mt-8">
      <creative-html-item
        v-for="crea in creatives"
        :key="crea.id"
        :creative="crea"
        :show-actions="showActions"
        :is-selected-publisher="isCopyAvailable"
        :is-publisher-role="isPublisherRole"
        @delete="$emit('delete', crea)"
        @edit="$emit('edit', crea)"
        @copy="onCopy"
      />
      <template #no-data-title> There are not <span class="italic">HTML</span> creatives yet </template>
    </creative-list>
    <div v-else>
      <line-loader v-for="crea in creatives" :key="crea.id" class="h-14"></line-loader>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CreativeList from '@/components/molecules/modules/ecommerce/offer/CreativeList';
import CreativeHtmlItem from '@/components/molecules/modules/ecommerce/offer/CreativeHtmlItem';
import { getPublisherTrackerLink } from '@/services/modules/ecommerce/offer/creatives/html/getPublisherTrackerLink';
import { copyToClipboard } from '@/utils/copyToClipboard';
import CreativeHtml from '@/model/modules/ecommerce/offer/creative/creativeHtml/CreativeHtml';
import { creativeFormMixin } from '@/mixins/modules/ecommerce/creativeFormMixin';
import apiRequest from '@/utils/apiRequest';
import LineLoader from '@/components/atoms/Loaders/LineLoader';
import { USER } from '@/store/modules/auth/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'CreativeListHtml',
  components: {
    CreativeList,
    CreativeHtmlItem,
    LineLoader,
  },
  mixins: [creativeFormMixin],
  props: {
    /** @type: Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    creatives: {
      type: Array,
      default: () => null,
    },
    isCopyAvailable: {
      type: Boolean,
      defalut: () => false,
    },
  },
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
    }),
    userRole() {
      return this.user.contextRoles.find(role => role.context === this.contextActive.id).role;
    },
    isPublisherRole() {
      return this.userRole === ROLES.PUBLISHER.id;
    },
  },
  methods: {
    async onCopy(creative) {
      if (!creative.publisher || !this.offer.id) return;
      const htmlCreative = new CreativeHtml(
        creative.id,
        creative.publisher,
        creative.size,
        creative.name,
        creative.code,
        creative.keepHtmlLinks
      );
      this.setLoadingToast(htmlCreative);

      await apiRequest(async () => {
        const { data } = await getPublisherTrackerLink(this.offer.id, htmlCreative);
        copyToClipboard(data);
        this.setSuccessLoadingToast();
      }).catch(() => {
        this.setErrorLoadingToast();
      });
    },
  },
};
</script>
<style scoped>
.h-14 {
  height: 3.6rem;
}
</style>
