var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "overlay",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _vm.showActions
                        ? _c(
                            "button",
                            {
                              staticClass: "action-button",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$emit("edit", _vm.creative)
                                },
                              },
                            },
                            [_c("edit-svg")],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "sun-popover",
                        {
                          staticClass: "mx-1 static flex-none",
                          attrs: { "text-info": _vm.clipboardHint },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "action-button",
                              class: { disabled: !_vm.isSelectedPublisher },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$emit("copy", _vm.creative)
                                },
                              },
                            },
                            [_c("code-svg")],
                            1
                          ),
                        ]
                      ),
                      _vm.showActions
                        ? _c(
                            "button",
                            {
                              staticClass: "action-button red",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.showDelete.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("rubbish-bin-svg", {
                                staticClass: "text-white",
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("img", {
            staticClass: "creative-preview-image mx-auto",
            attrs: { src: _vm.creative.url, alt: _vm.alt },
          }),
        ]
      ),
      _c("delete-modal", {
        attrs: { open: _vm.showDeleteModal },
        on: {
          confirm: _vm.confirmDeleteModal,
          cancel: function ($event) {
            _vm.showDeleteModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [
                _vm._v(
                  " You are going to remove the creative. This action can not be undone. "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }