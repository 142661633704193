export default {
  commissionType: {
    FIX_PRICE: 'FIX_PRICE',
    PERCENT_COMMISSION: 'PERCENT_COMMISSION',
    DEFAULT_COMMISSION: 'DEFAULT_COMMISSION',
  },
  tableHeaders: {
    ACTIVE: 'ACTIVE',
    PIXEL_TEST: 'PIXEL TEST',
    PUBLISHER: 'PUBLISHER',
    DIRECT_LINK: 'DIRECT LINK',
    IMPRESSION_CODE: 'IMPRESSION CODE',
    PUBLISHER_PRICE: 'PUBLISHER PRICE',
    PUBLISHER_COMISSION: 'PUBLISHER COMISSION',
    OUR_COMMISSION: 'OUR MARGIN',
    ACTION: 'ACTIONS',
  },
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  percentage: {
    TYPE: 'CPA',
    VALUE_TYPE: 'PERCENTAGE',
  },
};
